.id {
  display: flex;
  height: 50px;
  width: 117px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  background-color: #F5F5F5;

  &__logo {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}