import {FormSteps} from '@models/form-steps.enum';
import {TranslationScopes} from '@models/translations.model';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

export function useTranslatedSteps() {
    const {t} = useTranslation();
    /**
     * Mapping translations because the type of the translations prop on progress component
     * is not clear on what kind of structure works with it
     */
    const translatedSteps = useMemo(() => {
        return Object.values(FormSteps ?? {})?.map((step) =>
            t(`${TranslationScopes.STEPS}.${step}`),
        );
    }, [t]);
    return translatedSteps;
}
