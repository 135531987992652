import {FormErrors} from '@models/translations.model';
import {
    ValidatorFn,
    ValidatorFnParameters,
    ValidatorFnWithArgs,
    ValidatorFnWithArgsParameters,
} from '@models/validation.model';

/**
 *
 * @param args Set containing an array of other values and the error to use
 * @returns Regular validator function that checks the provided arguments in closure
 * @description Check if any of the fields is truthy, meant to enforce required on at least one field of several
 */
export function validateRequiredOr(
    args: ValidatorFnWithArgsParameters,
): ReturnType<ValidatorFnWithArgs> {
    // Destructure provided arguments
    const [otherValues, error] = args as [
        Parameters<ValidatorFn>,
            string | FormErrors,
    ];
    return (value: ValidatorFnParameters): ReturnType<ValidatorFn> => {
        // Check if all provided values are truthy
        const isOtherValuesArray = Array.isArray(otherValues);
        const isValueArray = Array.isArray(value);
        if (isOtherValuesArray && otherValues?.length) {
            return true;
        }
        if (!isOtherValuesArray && otherValues) {
            return true;
        }
        // Check the current input itself if other field is falsy
        if (isValueArray && value?.length) {
            return true;
        }
        if (!isValueArray && value) {
            return true;
        }
        return error;
    };
}
