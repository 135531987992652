export enum Languages {
    ET = 'estonian',
    EN = 'english',
    RU = 'russian',
}

export enum LanguagesShort {
    ET = 'et',
    EN = 'en',
}
