import {useTranslation} from 'react-i18next';
import {ActionCard, Separator, TTNewButton, TTNewCol, TTNewRow, Typography} from 'taltech-styleguide';
import {FormSteps} from '@models/form-steps.enum';
import {FormActions, Labels, ModuleFields, StudyFields, TranslationScopes} from '@models/translations.model';
import {FieldArray, FieldValues, useFieldArray, useFormContext} from "react-hook-form";
import {useCallback} from "react";
import {getValidators} from "@validation/getValidators.ts";
import {FormValidators} from "@models/validation.model.ts";
import FormInput from "@components/Form/FormInput/FormInput.tsx";
import {defaultVideoObject} from "@constants/default-values.constant.ts";

export function ModulesStep() {
    const {t} = useTranslation();
    const {control, trigger} = useFormContext();
    const {fields, remove, append} = useFieldArray({
        control,
        name: StudyFields.MODULES,
    });

    const removeHandler = useCallback(
        (index: number) => {
            remove(index);
            trigger(StudyFields.MODULES);
        },
        [remove, trigger],
    );

    const moduleLabel = t(`${TranslationScopes.LABELS}.${Labels.MODULE}`);

    return (
        <ActionCard>
            <Typography as="h4">
                {t(`${TranslationScopes.STEPS}.${FormSteps.MODULES}`)}
                <Typography color="gray-700" as="small" modifiers={['italic', 'normal']}>
                    {` - `}{t(`${TranslationScopes.LABELS}.${Labels.OPTIONAL}`)}
                </Typography>
            </Typography>

            {fields?.map((object, i) => {
                return (
                    <div key={object?.id} className="mb-4">
                        <TTNewRow alignItems="center" justifyContent="start">
                            <TTNewCol size={2}>
                                <Typography as="span" visual="h5" className="mb-0">
                                    {i + 1}. {moduleLabel}
                                </Typography>
                            </TTNewCol>
                            <TTNewCol>
                                <TTNewButton
                                    variant="tertiary"
                                    onClick={() => removeHandler(i)}
                                >
                                    {t(`${TranslationScopes.ACTIONS}.${FormActions.REMOVE}`)}
                                </TTNewButton>
                            </TTNewCol>
                        </TTNewRow>

                        <FormInput
                            key={`${StudyFields.MODULES}.${i}.${ModuleFields.NAME}`}
                            id={`${StudyFields.MODULES}.${i}.${ModuleFields.NAME}`}
                            label={ModuleFields.NAME}
                            translationScope={TranslationScopes.SUBJECT_FIELDS}
                            registerOptions={{
                                ...getValidators([FormValidators.REQUIRED])
                            }}
                        />

                        <FormInput
                            key={`${StudyFields.MODULES}.${i}.${ModuleFields.OBJECTIVE}`}
                            id={`${StudyFields.MODULES}.${i}.${ModuleFields.OBJECTIVE}`}
                            label={ModuleFields.OBJECTIVE}
                            translationScope={TranslationScopes.MODULE_FIELDS}
                            registerOptions={{
                                ...getValidators([FormValidators.REQUIRED])
                            }}
                        />
                    </div>
                )
            })
            }
            <Separator/>
            <TTNewButton
                variant="secondary"
                onClick={() =>
                    append(defaultVideoObject as FieldArray<FieldValues>)
                }
            >
                {t(`${TranslationScopes.ACTIONS}.${FormActions.ADD}`)} {moduleLabel}
            </TTNewButton>
        </ActionCard>
    );
}
