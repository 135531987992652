import {handleFetch} from "@utils/handleFetch.ts";
import {ApiEndpoints, ApiResponseWithErrors, RequestMethods} from "@models/api.model.ts";
import {getCookie} from "@utils/getCookie.ts";
import {authTokenKey} from "@constants/default-values.constant.ts";
import {API_URL} from "@constants/endpoints.constant.ts";

export async function fetchSingleCurriculum(curriculumId: number) {
    const curriculumData = await handleFetch<ApiResponseWithErrors>({
        url: `${API_URL}${ApiEndpoints.CURRICULUM}/${curriculumId}`,
        method: RequestMethods.GET,
        headers: {
            Authorization: `Bearer ${getCookie(authTokenKey)}`,
        },
        throwOnError: false,
    })
        /**
         * Could use logging here in a production app
         */
        .catch((err) => console.error('Query failed', err));

    if (curriculumData?.status === 200 && !curriculumData?.message) {
        return curriculumData;
    }

    return null;
}
