import {
    AdvancedTable,
    ButtonGroup,
    Heading,
    TTNewButton,
    TTNewCard,
    TTNewCardContent,
    TTNewCol,
    TTNewRow,
    Typography
} from 'taltech-styleguide';

import * as styles from './Dashboard.module.scss';
import {useLoaderData, useNavigate} from "react-router-dom";
import {Routes} from "@models/routes.enum.ts";
import {DashboardFields, StudyFields, TranslationScopes} from "@models/translations.model.ts";
import {StudyProgramFormData} from "@models/general-form.types.ts";
import {useTranslation} from "react-i18next";

export function Dashboard() {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [
        studyPrograms,
        courses,
    ] = useLoaderData() as [[], []];
    const handleCreateNewStudyProgram = () => {
        navigate(Routes.NEW_CURRICULUM);
    }

    const columns = [
        {
            dataField: StudyFields.NAME,
            sort: true,
            text: t(`${TranslationScopes.DASHBOARD}.${DashboardFields.STUDY_PROGRAMMES}`),
        },
        {
            dataField: 'dateRange',
            sort: true,
            text: t(`${TranslationScopes.DASHBOARD}.${DashboardFields.SCHEDULE}`),
            formatter: (_val: string, item: StudyProgramFormData) => {
                if (!item[StudyFields.START_DATE] || !item[StudyFields.END_DATE]) {
                    return '-';
                }

                const startDate = new Date(item[StudyFields.START_DATE]);
                const startDay = startDate.getDate().toString().padStart(2, '0'); // Pad with zero if needed
                const startMonth = (startDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed

                const endDate = new Date(item[StudyFields.START_DATE]);
                const endDay = endDate.getDate().toString().padStart(2, '0'); // Pad with zero if needed
                const endMonth = (endDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
                const endYear = (endDate.getFullYear()).toString(); // Months are 0-indexed

                const formattedStartDate = `${startDay}.${startMonth}`;
                const formattedEndDate = `${endDay}.${endMonth}.${endYear}`;

                return `${formattedStartDate} - ${formattedEndDate}`
            }
        },
        {
            dataField: StudyFields.TOTAL_HOURS,
            sort: true,
            text: t(`${TranslationScopes.DASHBOARD}.${DashboardFields.ECTS_CREDITS}`),
            formatter: (val: number) => {
                return val ? `${val} EAP` : '-';
            }
        },
        {
            dataField: StudyFields.STATUS,
            sort: true,
            text: t(`${TranslationScopes.DASHBOARD}.${DashboardFields.STATUS}`),
        },
    ];

    const handleRowClick = (_e: any, rowData: StudyProgramFormData) => {
        navigate(`${Routes.CURRICULUM}/${rowData[StudyFields.ID]}`)
    }

    return (
        <TTNewCol size={12}>
            <Heading
                as="h4"
                modifiers={[
                    'uppercase'
                ]}
            >
                {t(`${TranslationScopes.DASHBOARD}.${DashboardFields.NOTIFICATIONS}`)}
            </Heading>
            <TTNewRow wrap="nowrap">
                <TTNewCol size={3}>
                    <TTNewCard direction="column">
                        <TTNewCardContent border={{position: 'top', notice: '!', color: 'info'}}>
                            <Heading
                                as="h3"
                                className=""
                                modifiers={[
                                    'uppercase'
                                ]}
                                visual="h5"
                            >
                                Uus õppekava
                            </Heading>
                            <Typography>Sind lisati koolitajaks Teenusedisain II mikrokraadi õppekaval.</Typography>
                            <ButtonGroup alignment="right">
                                <TTNewButton size="sm">
                                    Vaata õppekava
                                </TTNewButton>
                            </ButtonGroup>
                        </TTNewCardContent>
                    </TTNewCard>
                </TTNewCol>
                <TTNewCol size={3}>
                    <TTNewCard>
                        <TTNewCardContent border={{position: 'top', color: 'light'}}>
                            <Heading
                                as="h3"
                                className=""
                                modifiers={[
                                    'uppercase'
                                ]}
                                visual="h5"
                            >
                                Uus õppekava
                            </Heading>
                            <Typography>Sind lisati koolitajaks Teenusedisain II mikrokraadi õppekaval.</Typography>
                            <ButtonGroup alignment="right">
                                <TTNewButton size="sm">
                                    Vaata õppekava
                                </TTNewButton>
                            </ButtonGroup>
                        </TTNewCardContent>
                    </TTNewCard>
                </TTNewCol>
                <TTNewCol size={3}>
                    <TTNewCard>
                        <TTNewCardContent border={{position: 'top', color: 'light'}}>
                            <Heading
                                as="h3"
                                className=""
                                modifiers={[
                                    'uppercase'
                                ]}
                                visual="h5"
                            >
                                Uus õppekava
                            </Heading>
                            <Typography>Sind lisati koolitajaks Teenusedisain II mikrokraadi õppekaval.</Typography>
                            <ButtonGroup alignment="right">
                                <TTNewButton size="sm">
                                    Vaata õppekava
                                </TTNewButton>
                            </ButtonGroup>
                        </TTNewCardContent>
                    </TTNewCard>
                </TTNewCol>
            </TTNewRow>

            <TTNewRow className="mb-3 mt-4" alignItems="center">
                <TTNewCol size={3}>
                    <Heading
                        as="h4"
                        className="mb-0"
                        modifiers={[
                            'uppercase'
                        ]}
                    >
                        {t(`${TranslationScopes.DASHBOARD}.${DashboardFields.COURSES_TITLE}`)}
                    </Heading>
                </TTNewCol>
                <TTNewCol size={3}>
                    <ButtonGroup className="me-4" alignment="right">
                        <TTNewButton variant="link">
                            {t(`${TranslationScopes.DASHBOARD}.${DashboardFields.ALL_COURSES}`)}
                        </TTNewButton>
                    </ButtonGroup>
                </TTNewCol>
                <TTNewCol size={2}>
                    <Heading
                        as="h4"
                        className="mb-0"
                        modifiers={[
                            'uppercase'
                        ]}
                    >
                        {t(`${TranslationScopes.DASHBOARD}.${DashboardFields.STUDY_PROGRAMMES_TITLE}`)}
                    </Heading>
                </TTNewCol>
                <TTNewCol size={2}>
                    <ButtonGroup className="me-4" alignment="right">
                        <TTNewButton variant="link">
                            {t(`${TranslationScopes.DASHBOARD}.${DashboardFields.ALL_STUDY_PROGRAMMES}`)}
                        </TTNewButton>
                    </ButtonGroup>
                </TTNewCol>
                <TTNewCol size={2}>
                    <ButtonGroup className="me-4" alignment="right">
                        <TTNewButton variant="primary" onClick={handleCreateNewStudyProgram}>
                            {t(`${TranslationScopes.DASHBOARD}.${DashboardFields.CREATE_NEW_STUDY_PROGRAMME}`)}
                        </TTNewButton>
                    </ButtonGroup>
                </TTNewCol>
            </TTNewRow>

            <TTNewRow>
                <TTNewCol size={6}>
                    <TTNewCard>
                        <TTNewCardContent>
                            <AdvancedTable
                                columns={columns}
                                data={courses}
                                keyField="id"
                                pagination={{
                                    sizePerPage: 5,
                                    sizePerPageRenderer: () => null,
                                }}
                            />
                        </TTNewCardContent>
                    </TTNewCard>
                </TTNewCol>
                <TTNewCol size={6}>
                    <TTNewCard>
                        <TTNewCardContent>
                            <AdvancedTable
                                columns={columns}
                                data={studyPrograms}
                                keyField="id"
                                rowClasses={styles.tableRow}
                                rowEvents={{
                                    onClick: handleRowClick,
                                }}
                                pagination={{
                                    sizePerPage: 5,
                                    sizePerPageList: [5],
                                }}
                            />
                        </TTNewCardContent>
                    </TTNewCard>
                </TTNewCol>
            </TTNewRow>
        </TTNewCol>
    );
}

