export enum StudyGroups {
    GENERAL_CURRICULUM = 'general_curriculum',
    EDUCATIONAL = 'educational',
    LIBERAL_ARTS = 'liberal_arts_in_humanities',
    SOCIAL_SCIENCES = 'social_sciences_journalism_and_information_dissemination',
    BUSINESS_ADMIN = 'business_administration_and_law',
    NATURAL_SCIENCES = 'natural_sciences_mathematics_and_statistics',
    IC_TECHNOLOGIES = 'information_and_communication_technologies',
    ENGINEERING = 'engineering_manufacturing_and_construction',
    AGRICULTURE = 'agriculture_forestry_fisheries_and_veterinary',
    HEALTH_AND_WELFARE = 'health_and_welfare',
    SERVICES = 'services',
}
