export default function calculateFieldTotal(fieldValues: {
    [x: string]: string | number;
}[], fieldKey: string | number) {
    let total = 0;

    for (const fieldValue of fieldValues) {
        total += +fieldValue[fieldKey] || 0;
    }

    return total;
}
