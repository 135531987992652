import {PropsWithChildren} from 'react';
import {TTNewRow} from 'taltech-styleguide';

export function Layout({children}: PropsWithChildren) {
    return (
        <TTNewRow>
            {children}
        </TTNewRow>
    );
}
