export enum ApiEndpoints {
    EMAIL_REGISTER = '/auth/register',
    EMAIL_LOGIN = '/auth/login',
    LOG_OUT = '/auth/logout',
    CURRICULUM = '/curriculum',
    ALL_COURSES = '/subjects',
    UPDATE_CURRICULUM = '/curriculum/?action=draft',
    SAVE_CURRICULUM = '/curriculum/:id/?action=submit',
}

export enum RequestMethods {
    GET = 'GET',
    PUT = 'PUT',
    PATCH = 'PATCH',
    POST = 'POST',
}

export enum ContentTypes {
    JSON = 'application/json',
    MULTI_PART = 'multipart/form-data',
    BLOB = 'application/octet-stream',
}

export interface RequestOptions {
    headers?: HeadersInit | undefined;
    method?: RequestMethods;
    body?: BodyInit | null | undefined;
}

export enum RequestHeaders {
    AUTHORIZATION = 'Authorization',
    CONTENT_LENGTH = 'Content-Length',
    CONTENT_TYPE = 'Content-Type',
}

export interface ApiResponse {
    [key: string]: unknown;
    data?: {
        [key: string]: unknown;
    }
}

export interface ApiResponseWithErrors extends ApiResponse {
    errors?: { [key: string]: string };
    status?: number;
}

export interface HandleFetchOptions {
    url?: string;
    endpoint?: ApiEndpoints;
    method: RequestMethods;
    headers?: HeadersInit;
    data?: unknown;
    multipart?: boolean;
    throwOnError?: boolean;
    includeStatus?: boolean;
}

export enum ApiStatusCodes {
    UNAUTHENTICATED = 403,
}
