import {FormErrors} from '@models/translations.model';
import {ValidatorFn, ValidatorFnParameters} from '@models/validation.model';

export function validateArrayObjectFields(
    objectArray: ValidatorFnParameters,
): ReturnType<ValidatorFn> {
    if (!Array.isArray(objectArray) || objectArray?.length === 0) {
        return FormErrors.ARRAY_EMPTY;
    }
    const hasFilledFields = objectArray?.every((object) => {
        return Object.values(object ?? {})?.every(
            (value) => (Array.isArray(value) && value?.length) || value,
        );
    });
    return hasFilledFields || FormErrors.ARRAY_REQUIRED;
}
