/**
 *
 * @param key cookie that you want to delete
 * @description Sets the expiration date of the cookie to start of epoch to cause deletion
 */
export function deleteCookie(key: string): void {
    /**
     * Creating an expired date, as expired cookies get removed
     */
    const expirationDate = new Date(1).toUTCString();
    /**
     * Setting expiration date on the cookie
     */
    document.cookie = `${key}=deleted; expires=${expirationDate}`;
}
