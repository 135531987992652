import {Labels, ModuleFields, SubjectFields, VideoFields} from "@models/translations.model.ts";
import {ModuleChild, SubjectChild, VideoChild} from "@models/misc-form-fields.model.ts";

export const authTokenKey = 'auth_token';
export const questionsContactEmail = 'avatudope@taltech.ee';

export const defaultDashboardData = {
    notifications: [],
    subjects: [],
    curriculums: [],
}

export const defaultVideoObject: VideoChild = {
    [VideoFields.LINK]: '',
    [VideoFields.TITLE]: '',
    [VideoFields.ALT]: '',
};

export const defaultModuleObject: ModuleChild = {
    [ModuleFields.NAME]: '',
    [ModuleFields.OBJECTIVE]: '',
};

export const defaultSubjectObject: SubjectChild = {
    [SubjectFields.MODULE]: '',
    [SubjectFields.NAME]: '',
    [SubjectFields.CODE]: '',
    [SubjectFields.START_DATE]: '',
    [SubjectFields.END_DATE]: '',
    [SubjectFields.INSTRUCTION_LANGUAGE]: '',
    [SubjectFields.TARGET_AUDIENCE]: '',
    [SubjectFields.OBJECTIVE]: '',
    [SubjectFields.LEARNING_OUTCOME]: '',
    [SubjectFields.PREREQUISITES]: '',
    [SubjectFields.PREREQUISITE_COURSES]: [],
    [Labels.LECTURE_HOURS]: 0,
    [Labels.PRACTICAL_HOURS]: 0,
    [Labels.INDEPENDENT_HOURS]: 0,
    [SubjectFields.ECTS_CREDITS]: 0,
    [SubjectFields.LOCATION]: '',
    [SubjectFields.LECTURER]: '',
    [SubjectFields.LECTURE_ROOMS]: [],
};
