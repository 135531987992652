.radio {
  &__wrapper {
    display: flex;
    gap: 24px;
  }

  &__legend {
    /* Unifying text with other input labels */
    font-size: 0.75rem;
    margin: 0;
    padding: 0;
    line-height: 16px;
    float: none;
  }
}

.fieldset {
  margin-top: 4px;
}