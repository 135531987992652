import {useTranslation} from 'react-i18next';
import {FieldArray, FieldValues, useFieldArray, useFormContext} from 'react-hook-form';
import {ActionCard, Separator, TTNewButton, TTNewCol, TTNewRow, Typography} from 'taltech-styleguide';
import {FormSteps} from '@models/form-steps.enum';
import {
    FormActions,
    Labels,
    ModuleFields,
    StudyFields,
    SubjectFields,
    TranslationScopes
} from '@models/translations.model';
import {defaultSubjectObject} from '@constants/default-values.constant.ts';
import {useCallback, useMemo} from "react";
import FormAccordion from "@components/Form/FormAccordion/FormAccordion.tsx";
import FormSelect from "@components/Form/FormSelect/FormSelect.tsx";
import {FormSelectOption} from "@models/form-input.types.ts";
import {ModuleChild} from "@models/misc-form-fields.model.ts";
import FormInput from "@components/Form/FormInput/FormInput.tsx";
import {getValidators} from "@validation/getValidators.ts";
import {FormValidators} from "@models/validation.model.ts";
import FormDateRangePicker from "@components/Form/FormDateRangePicker/FormDateRangePicker.tsx";
import {Languages} from "@models/languages.enum.ts";
import TotalHours from "@components/common/TotalHours.tsx";

export function CoursesStep() {
    const {t} = useTranslation();
    const {control, trigger, getValues} = useFormContext();
    const {fields, remove, append} = useFieldArray({
        control,
        name: StudyFields.COURSES,
    });

    const removeHandler = useCallback(
        (index: number) => {
            remove(index);
            trigger(StudyFields.COURSE);
        },
        [remove, trigger],
    );

    const subjectLabel = t(`${TranslationScopes.LABELS}.${Labels.COURSE}`);


    const moduleOptions: FormSelectOption[] = getValues(StudyFields.MODULES).map((module: ModuleChild) => ({
        label: module[ModuleFields.NAME],
        value: module[ModuleFields.NAME]
    } as FormSelectOption));

    const languageOptions = useMemo(
        () => Object.values(Languages)?.map((lang) => ({
            label: t(`${TranslationScopes.LANGUAGES}.${lang}`),
            value: lang
        })),
        [t]
    );

    return (
        <ActionCard>
            <Typography as="h2">
                {t(`${TranslationScopes.STEPS}.${FormSteps.COURSES}`)}
            </Typography>

            <div>
                {fields?.map((object, i) => {
                    return (
                        <div key={object?.id} className="mb-4">
                            <TTNewRow alignItems="center" justifyContent="start">
                                <TTNewCol size={2}>
                                    <Typography as="span" visual="h5" className="mb-0">
                                        {i + 1}. {subjectLabel}
                                    </Typography>
                                </TTNewCol>
                                <TTNewCol>
                                    <TTNewButton
                                        variant="tertiary"
                                        onClick={() => removeHandler(i)}
                                    >
                                        {t(`${TranslationScopes.ACTIONS}.${FormActions.REMOVE}`)}
                                    </TTNewButton>
                                </TTNewCol>
                            </TTNewRow>

                            <FormAccordion id={Labels.COURSE_INFO_TITLE}
                                           name={t(`${TranslationScopes.LABELS}.${Labels.COURSE_INFO_TITLE}`)}
                                           isOpen={true}>
                                <FormSelect
                                    key={`${StudyFields.COURSES}.${i}.${SubjectFields.MODULE}`}
                                    id={`${StudyFields.COURSES}.${i}.${SubjectFields.MODULE}`}
                                    label={SubjectFields.MODULE}
                                    placeholder={t(`${TranslationScopes.LABELS}.${Labels.SELECT_MODULE_PLACEHOLDER}`)}
                                    translationScope={TranslationScopes.SUBJECT_FIELDS}
                                    values={moduleOptions}
                                />
                                <FormInput
                                    key={`${StudyFields.COURSES}.${i}.${SubjectFields.CODE}`}
                                    id={`${StudyFields.COURSES}.${i}.${SubjectFields.CODE}`}
                                    label={SubjectFields.CODE}
                                    translationScope={TranslationScopes.SUBJECT_FIELDS}
                                    registerOptions={{
                                        ...getValidators([FormValidators.REQUIRED])
                                    }}
                                />
                                <FormInput
                                    key={`${StudyFields.COURSES}.${i}.${SubjectFields.NAME}`}
                                    id={`${StudyFields.COURSES}.${i}.${SubjectFields.NAME}`}
                                    label={SubjectFields.NAME}
                                    translationScope={TranslationScopes.SUBJECT_FIELDS}
                                    registerOptions={{
                                        ...getValidators([FormValidators.REQUIRED])
                                    }}
                                />
                                <FormDateRangePicker
                                    id={`${SubjectFields.START_DATE}-${SubjectFields.END_DATE}`}
                                    firstDateId={SubjectFields.START_DATE}
                                    secondDateId={SubjectFields.END_DATE}
                                    registerOptions={{
                                        ...getValidators([FormValidators.REQUIRED])
                                    }}
                                    translationScope={TranslationScopes.SUBJECT_FIELDS}
                                />
                                <FormSelect
                                    key={`${StudyFields.COURSES}.${i}.${SubjectFields.INSTRUCTION_LANGUAGE}`}
                                    id={`${StudyFields.COURSES}.${i}.${SubjectFields.INSTRUCTION_LANGUAGE}`}
                                    label={SubjectFields.INSTRUCTION_LANGUAGE}
                                    placeholder={t(`${TranslationScopes.LABELS}.${Labels.LANGUAGE_PLACEHOLDER}`)}
                                    translationScope={TranslationScopes.SUBJECT_FIELDS}
                                    values={languageOptions}
                                />
                            </FormAccordion>

                            <FormAccordion id={Labels.COURSE_OBJECTIVES_TITLE}
                                           name={t(`${TranslationScopes.LABELS}.${Labels.COURSE_OBJECTIVES_TITLE}`)}>
                                <FormInput
                                    as="textarea"
                                    key={`${StudyFields.COURSES}.${i}.${SubjectFields.TARGET_AUDIENCE}`}
                                    id={`${StudyFields.COURSES}.${i}.${SubjectFields.TARGET_AUDIENCE}`}
                                    label={SubjectFields.TARGET_AUDIENCE}
                                    placeholder={t(`${TranslationScopes.LABELS}.${Labels.TARGET_AUDIENCE_PLACEHOLDER}`)}
                                    translationScope={TranslationScopes.SUBJECT_FIELDS}
                                />
                                <FormInput
                                    as="textarea"
                                    key={`${StudyFields.COURSES}.${i}.${SubjectFields.OBJECTIVE}`}
                                    id={`${StudyFields.COURSES}.${i}.${SubjectFields.OBJECTIVE}`}
                                    label={SubjectFields.OBJECTIVE}
                                    placeholder={t(`${TranslationScopes.LABELS}.${Labels.OBJECTIVE_PLACEHOLDER}`)}
                                    translationScope={TranslationScopes.SUBJECT_FIELDS}
                                    registerOptions={{
                                        ...getValidators([FormValidators.REQUIRED])
                                    }}
                                />
                                <FormInput
                                    as="textarea"
                                    key={`${StudyFields.COURSES}.${i}.${SubjectFields.LEARNING_OUTCOME}`}
                                    id={`${StudyFields.COURSES}.${i}.${SubjectFields.LEARNING_OUTCOME}`}
                                    label={SubjectFields.LEARNING_OUTCOME}
                                    placeholder={t(`${TranslationScopes.SUBJECT_FIELDS}.${SubjectFields.LEARNING_OUTCOME}`)}
                                    translationScope={TranslationScopes.SUBJECT_FIELDS}
                                    registerOptions={{
                                        ...getValidators([FormValidators.REQUIRED])
                                    }}
                                />
                                <FormInput
                                    as="textarea"
                                    key={`${StudyFields.COURSES}.${i}.${SubjectFields.PREREQUISITES}`}
                                    id={`${StudyFields.COURSES}.${i}.${SubjectFields.PREREQUISITES}`}
                                    label={SubjectFields.PREREQUISITES}
                                    placeholder={t(`${TranslationScopes.LABELS}.${Labels.PREREQUISITES_PLACEHOLDER}`)}
                                    translationScope={TranslationScopes.SUBJECT_FIELDS}
                                />
                                <FormSelect
                                    key={`${StudyFields.COURSES}.${i}.${SubjectFields.PREREQUISITE_COURSES}`}
                                    id={`${StudyFields.COURSES}.${i}.${SubjectFields.PREREQUISITE_COURSES}`}
                                    label={SubjectFields.PREREQUISITE_COURSES}
                                    placeholder={t(`${TranslationScopes.LABELS}.${Labels.PREREQUISITE_COURSES_PLACEHOLDER}`)}
                                    translationScope={TranslationScopes.SUBJECT_FIELDS}
                                    values={languageOptions}
                                />
                            </FormAccordion>
                            <FormAccordion id={Labels.COURSE_ORGANIZATION_TITLE}
                                           name={t(`${TranslationScopes.LABELS}.${Labels.COURSE_ORGANIZATION_TITLE}`)}>
                                <TTNewRow>
                                    <TTNewCol>
                                        <FormInput
                                            key={`${StudyFields.COURSES}.${i}.${Labels.LECTURE_HOURS}`}
                                            id={`${StudyFields.COURSES}.${i}.${Labels.LECTURE_HOURS}`}
                                            label={Labels.LECTURE_HOURS}
                                            type="number"
                                            placeholder={t(`${TranslationScopes.LABELS}.${Labels.ADD_NUMBER}`)}
                                            translationScope={TranslationScopes.LABELS}
                                            registerOptions={{
                                                ...getValidators([FormValidators.REQUIRED])
                                            }}
                                        />
                                    </TTNewCol>
                                    <TTNewCol>
                                        <FormInput
                                            key={`${StudyFields.COURSES}.${i}.${Labels.PRACTICAL_HOURS}`}
                                            id={`${StudyFields.COURSES}.${i}.${Labels.PRACTICAL_HOURS}`}
                                            label={Labels.PRACTICAL_HOURS}
                                            type="number"
                                            placeholder={t(`${TranslationScopes.LABELS}.${Labels.ADD_NUMBER}`)}
                                            translationScope={TranslationScopes.LABELS}
                                            registerOptions={{
                                                ...getValidators([FormValidators.REQUIRED])
                                            }}
                                        />
                                    </TTNewCol>
                                    <TTNewCol>
                                        <FormInput
                                            key={`${StudyFields.COURSES}.${i}.${Labels.INDEPENDENT_HOURS}`}
                                            id={`${StudyFields.COURSES}.${i}.${Labels.INDEPENDENT_HOURS}`}
                                            label={Labels.INDEPENDENT_HOURS}
                                            type="number"
                                            placeholder={t(`${TranslationScopes.LABELS}.${Labels.ADD_NUMBER}`)}
                                            translationScope={TranslationScopes.LABELS}
                                            registerOptions={{
                                                ...getValidators([FormValidators.REQUIRED])
                                            }}
                                        />
                                    </TTNewCol>
                                </TTNewRow>
                                <TotalHours control={control} index={i}
                                            key={`${StudyFields.COURSES}.${i}.${Labels.TOTAL_HOURS}`}/>
                                <FormInput
                                    key={`${StudyFields.COURSES}.${i}.${Labels.ECTS_CREDITS}`}
                                    id={`${StudyFields.COURSES}.${i}.${Labels.ECTS_CREDITS}`}
                                    label={Labels.ECTS_CREDITS}
                                    placeholder={t(`${TranslationScopes.LABELS}.${Labels.ADD_NUMBER}`)}
                                    translationScope={TranslationScopes.LABELS}
                                />
                                <FormInput
                                    as="textarea"
                                    key={`${StudyFields.COURSES}.${i}.${SubjectFields.LOCATION}`}
                                    id={`${StudyFields.COURSES}.${i}.${SubjectFields.LOCATION}`}
                                    label={SubjectFields.LOCATION}
                                    placeholder={t(`${TranslationScopes.LABELS}.${Labels.LOCATION_PLACEHOLDER}`)}
                                    translationScope={TranslationScopes.SUBJECT_FIELDS}
                                />
                            </FormAccordion>
                        </div>
                    );
                })}
            </div>

            <Separator/>
            <TTNewButton
                variant="secondary"
                onClick={() =>
                    append(defaultSubjectObject as FieldArray<FieldValues>)
                }
            >
                {t(`${TranslationScopes.ACTIONS}.${FormActions.ADD}`)} {subjectLabel}
            </TTNewButton>
        </ActionCard>
    );
}
