import {DateRegex, EmailRegex, UrlRegex,} from '@constants/regex.constant';
import {FormErrors} from '@models/translations.model';
import {
    CustomValidatorsMap,
    DefaultValidatorsMap,
    FormValidators,
    FormValidatorWithArgs,
    ValidatorFn,
    ValidatorFnWithArgs,
    Validators,
} from '@models/validation.model';
import {FieldValues, RegisterOptions} from 'react-hook-form';
import {validateArrayObjectFields} from './validators/validateArrayObjectFields';
import {validateRequiredOr} from './validators/validateRequiredOr';

const defaultFormValidatorsMap: DefaultValidatorsMap = {
    [FormValidators.REQUIRED]: FormErrors.REQUIRED,
    [FormValidators.EMAIL]: {
        pattern: {
            value: EmailRegex,
            message: FormErrors.EMAIL,
        },
    },
    [FormValidators.DATE]: {
        pattern: {
            value: DateRegex,
            message: FormErrors.DATE,
        },
    },
    [FormValidators.URL]: {
        pattern: {
            value: UrlRegex,
            message: FormErrors.URL,
        },
    },
};

const customFormValidatorsMap: CustomValidatorsMap = {
    [FormValidators.REQUIRED_OR]: validateRequiredOr as ValidatorFnWithArgs,
    [FormValidators.ARRAY_REQUIRED_OBJECTS]:
        validateArrayObjectFields as ValidatorFn,
};

/**
 *
 * @param validators array of {@link FormValidators validators} from the FormValidators enum
 * @returns object with the chosen validators added
 * @example registerOptions={{
 ...getValidators([FormValidators.REQUIRED])
 }}
 */
export function getValidators<Field extends string>(
    validators: (FormValidators | FormValidatorWithArgs)[],
): RegisterOptions<FieldValues, Field> {
    return validators?.reduce(
        (acc, curr) => {
            if (curr instanceof Object) {
                /**
                 * Call validator with args first if an object is passed in
                 */
                acc.validate = Object.assign(acc?.validate, {
                    [curr?.validator]: (
                        customFormValidatorsMap?.[curr?.validator] as ValidatorFnWithArgs
                    )?.(curr?.args),
                });
                return acc;
            }
            const defaultValidator = defaultFormValidatorsMap?.[curr];
            if (defaultValidator) {
                /**
                 * Most built-in react-hook-form validators take just the error message as a value
                 */
                if (typeof defaultValidator === 'string') {
                    acc[curr] = defaultValidator;
                } else {
                    /**
                     * The alternative built-in validators use pattern matching
                     */
                    acc = Object.assign(acc, defaultValidator);
                }
            } else {
                /**
                 * Add any custom validators that take in the input value and return result
                 */
                acc.validate = Object.assign(acc.validate, {
                    [curr]: customFormValidatorsMap?.[curr],
                });
            }
            return acc;
        },
        {validate: {}} as Validators,
    ) as RegisterOptions<FieldValues, Field>;
}
