import {FormInputProps} from '@models/form-input.types';
import {Labels, TranslationScopes} from '@models/translations.model';
import {getNestedProperty} from '@utils/getNestedProperty';
import {ReactElement} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {FormFeedback, Input, STATUS_TYPE, TTNewCol, Typography} from 'taltech-styleguide';

export default function FormInput<InputId extends string>({
                                                              id,
                                                              translationScope,
                                                              registerOptions,
                                                              infoLabel,
                                                              label,
                                                              optional,
                                                              placeholder,
                                                              customLabelContents,
                                                              hideValidity,
                                                              size,
                                                              ...inputProps
                                                          }: FormInputProps<InputId>): ReactElement {
    const {t} = useTranslation();
    const {
        register,
        formState: {errors}
    } = useFormContext();
    const fieldErrors = optional ? false : getNestedProperty(id, errors);
    const isValid = !fieldErrors;
    const isInvalid = fieldErrors;
    return (
        <div>
            <Typography color="gray-800" as="label" htmlFor={id}>
                {customLabelContents
                    ? customLabelContents
                    : t(`${translationScope}.${label}`)}
                {!registerOptions ?
                    <Typography color="gray-700" as="span" modifiers={['italic']}>
                        {` - `}{t(`${TranslationScopes.LABELS}.${Labels.OPTIONAL}`)}
                    </Typography> : null}
            </Typography>
            <TTNewCol size={size}>
                <Input
                    {...register(id, registerOptions)}
                    id={id}
                    isValid={hideValidity !== undefined ? isValid : hideValidity}
                    isInvalid={hideValidity !== undefined ? !!isInvalid : hideValidity}
                    placeholder={placeholder}
                    {...inputProps}
                />
            </TTNewCol>
            {infoLabel ? (
                <FormFeedback icon={null}>
                    {t(`${TranslationScopes.LABELS}.${infoLabel}`)}
                </FormFeedback>
            ) : null}
            {isInvalid && fieldErrors?.message ? (
                <FormFeedback type={STATUS_TYPE.DANGER} role="alert">
                    {t(`${TranslationScopes.ERRORS}.${fieldErrors?.message}`)}
                </FormFeedback>
            ) : null}
        </div>
    );
}
