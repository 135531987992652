import {Labels, TranslationScopes} from '@models/translations.model';
import {Trans, useTranslation} from 'react-i18next';

export function TranslatedListComponent() {
    const {t} = useTranslation();
    /**
     * Memoizing nested translations because they only change when language changes
     */
    return (
        <Trans
            i18nKey={`${TranslationScopes.LABELS}.${Labels.LOGIN_LIST_DESCRIPTION}`}
            components={[
                <ul key={Labels.LOGIN_LIST_DESCRIPTION}>
                    <li>
                        {t(`${TranslationScopes.LABELS}.${Labels.LOGIN_LIST_FIRST}`)}
                    </li>
                    <li>
                        {t(`${TranslationScopes.LABELS}.${Labels.LOGIN_LIST_SECOND}`)}
                    </li>
                    <li>
                        {t(`${TranslationScopes.LABELS}.${Labels.LOGIN_LIST_THIRD}`)}
                    </li>
                </ul>,
            ]}
        ></Trans>
    );
}
