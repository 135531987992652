import {GeneralInfoStep} from '@components/Form/FormSteps/GeneralInfoStep.tsx';
import {ReactElement, useMemo} from 'react';
import {ObjectivesStep} from '@components/Form/FormSteps/ObjectivesStep.tsx';
import {ModulesStep} from '@components/Form/FormSteps/ModulesStep.tsx';
import {CoursesStep} from '@components/Form/FormSteps/CoursesStep.tsx';
import {ManagementStep} from '@components/Form/FormSteps/ManagementStep.tsx';
import {ConditionsStep} from '@components/Form/FormSteps/ConditionsStep.tsx';

/**
 *
 * @param stepIndex numerical index for current step
 * @returns Step component matching the current step
 */
export function useFormStep(stepIndex: number) {
    return useMemo(() => {
        const steps = {
            0: <GeneralInfoStep/>,
            1: <ObjectivesStep/>,
            2: <ModulesStep/>,
            3: <CoursesStep/>,
            4: <ManagementStep/>,
            5: <ConditionsStep/>,
        } as { [index: string]: ReactElement };
        const step = steps[`${stepIndex}`];
        return step ?? null;
    }, [stepIndex]);
}
