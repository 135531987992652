import {handleFetch} from '@utils/handleFetch.ts';
import {ApiEndpoints, ApiResponseWithErrors, RequestMethods} from '@models/api.model.ts';
import {getCookie} from '@utils/getCookie.ts';
import {authTokenKey} from '@constants/default-values.constant.ts';
import {DashboardFields} from '@models/translations.model.ts';

export type DashboardData = {
    [key in Partial<DashboardFields | string>]: unknown;
}


export async function fetchDashboardData() {
    const fetchedStudyProgramsData = await handleFetch<ApiResponseWithErrors>({
        endpoint: ApiEndpoints.CURRICULUM,
        method: RequestMethods.GET,
        headers: {
            Authorization: `Bearer ${getCookie(authTokenKey)}`,
        },
        throwOnError: false,
    })
        /**
         * Could use logging here in a production app
         */
        .catch((err) => console.error('Query failed', err));
    const fetchedCoursesData = await handleFetch<ApiResponseWithErrors>({
        endpoint: ApiEndpoints.ALL_COURSES,
        method: RequestMethods.GET,
        headers: {
            Authorization: `Bearer ${getCookie(authTokenKey)}`,
        },
        throwOnError: false,
    })
        /**
         * Could use logging here in a production app
         */
        .catch((err) => console.error('Query failed', err));

    const returnData: DashboardData = {
        [DashboardFields.STATUS]: 0,
        [DashboardFields.NOTIFICATIONS]: [],
        [DashboardFields.STUDY_PROGRAMMES]: [],
        [DashboardFields.COURSES]: [],
    };

    if (fetchedStudyProgramsData?.status === 200 && !fetchedStudyProgramsData?.message) {
        returnData[DashboardFields.STATUS] = 1;
        returnData[DashboardFields.STUDY_PROGRAMMES] = fetchedStudyProgramsData?.data;
    }

    if (fetchedCoursesData?.status === 200 && fetchedCoursesData?.message) {
        returnData[DashboardFields.STATUS] = 1;
        returnData[DashboardFields.COURSES] = fetchedCoursesData?.data;
    }

    return returnData;
}
