import {FormSteps} from '@models/form-steps.enum';
import {Labels, StudyFields, TranslationScopes} from '@models/translations.model';
import {useTranslation} from 'react-i18next';
import {ActionCard, Typography} from 'taltech-styleguide';
import {getValidators} from "@validation/getValidators.ts";
import {FormValidators} from "@models/validation.model.ts";
import FormInput from "@components/Form/FormInput/FormInput.tsx";
import FormSelect from "@components/Form/FormSelect/FormSelect.tsx";
import {useMemo} from "react";
import {DocumentTypes} from "@models/document-types.enum.ts";

export function ConditionsStep() {
    const {t} = useTranslation();

    const documentTypeOptions = useMemo(
        () => Object.values(DocumentTypes)?.map((documentType) => ({
            label: t(`${TranslationScopes.EDUCATION_TYPES}.${documentType}`),
            value: documentType
        })),
        [t]
    );

    return (
        <ActionCard>
            <Typography as="h2">
                {t(`${TranslationScopes.STEPS}.${FormSteps.CONDITIONS}`)}
            </Typography>

            <FormInput
                as="textarea"
                key={`${StudyFields.COMPLETION_REQUIREMENTS}`}
                id={`${StudyFields.COMPLETION_REQUIREMENTS}`}
                label={StudyFields.COMPLETION_REQUIREMENTS}
                placeholder={t(`${TranslationScopes.LABELS}.${Labels.COMPLETION_REQUIREMENTS_PLACEHOLDER}`)}
                translationScope={TranslationScopes.STUDY_FIELDS}
                registerOptions={{
                    ...getValidators([FormValidators.REQUIRED])
                }}
            />


            <FormSelect
                key={StudyFields.COMPLETION_DOCUMENT}
                id={StudyFields.COMPLETION_DOCUMENT}
                label={StudyFields.COMPLETION_DOCUMENT}
                placeholder={t(`${TranslationScopes.LABELS}.${Labels.COMPLETION_DOCUMENT_PLACEHOLDER}`)}
                translationScope={TranslationScopes.STUDY_FIELDS}
                values={documentTypeOptions}
            />

            <FormInput
                key={StudyFields.CONT_EDUCATION_COST}
                id={StudyFields.CONT_EDUCATION_COST}
                label={StudyFields.CONT_EDUCATION_COST}
                type="number"
                translationScope={TranslationScopes.STUDY_FIELDS}
                registerOptions={{
                    ...getValidators([FormValidators.REQUIRED])
                }}
            />

            <FormInput
                as="textarea"
                key={StudyFields.ADDITIONAL_PRICING_INFO}
                id={StudyFields.ADDITIONAL_PRICING_INFO}
                label={StudyFields.ADDITIONAL_PRICING_INFO}
                placeholder={t(`${TranslationScopes.LABELS}.${Labels.ADDITIONAL_PRICING_INFO_PLACEHOLDER}`)}
                translationScope={TranslationScopes.STUDY_FIELDS}
            />
        </ActionCard>
    );
}
