import {Control, useWatch} from "react-hook-form";
import {Labels, StudyFields, TranslationScopes} from "@models/translations.model.ts";
import FormInput from "@components/Form/FormInput/FormInput.tsx";
import {getValidators} from "@validation/getValidators.ts";
import {FormValidators} from "@models/validation.model.ts";

export default function TotalHours({index, control}: { index: number, control: Control }) {
    const formValues = useWatch({
        name: StudyFields.COURSES,
        control
    });

    const placementHours = isNaN(+formValues[index][Labels.PRACTICAL_HOURS]) ? 0 : +formValues[index][Labels.PRACTICAL_HOURS];
    const contactHours = isNaN(+formValues[index][Labels.LECTURE_HOURS]) ? 0 : +formValues[index][Labels.LECTURE_HOURS];
    const independentHours = isNaN(+formValues[index][Labels.INDEPENDENT_HOURS]) ? 0 : +formValues[index][Labels.INDEPENDENT_HOURS];
    const total = placementHours + contactHours + independentHours;

    return (
        <FormInput
            id={`${StudyFields.COURSE}.${index}.${Labels.TOTAL_HOURS}`}
            label={Labels.TOTAL_HOURS}
            translationScope={TranslationScopes.LABELS}
            value={total}
            infoLabel={Labels.HOURS_TOTAL_DESCRIPTION}
            size={3}
            disabled={true}
            registerOptions={{
                ...getValidators([FormValidators.REQUIRED])
            }}
        />
    );
};
