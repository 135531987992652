import {FormSteps} from '@models/form-steps.enum';
import {SetStateAction, useCallback} from 'react';

export function useStepChange({
                                  setStep,
                              }: {
    setStep: (value: SetStateAction<number>) => void;
}) {
    return useCallback(
        (stepIncrement: number) => {
            setStep((prevStep) => {
                const newStep = prevStep + stepIncrement;
                if (newStep < 0 || newStep > Object.keys(FormSteps ?? {})?.length - 1) {
                    return prevStep;
                }
                return newStep;
            });
        },
        [setStep],
    );
}
