import {FormSelectOption, FormSelectProps} from '@models/form-input.types';
import {Labels, TranslationScopes} from '@models/translations.model';
import {Controller, FieldValues, RegisterOptions, useFormContext,} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {FormFeedback, STATUS_TYPE, Typeahead, Typography} from 'taltech-styleguide';

export default function FormSelect<InputId extends string>({
                                                               id,
                                                               translationScope,
                                                               registerOptions,
                                                               values,
                                                               placeholder,
                                                               multiple,
                                                               infoLabel,
                                                               label,
                                                               customLabelContents,
                                                           }: FormSelectProps<InputId>) {
    const {t} = useTranslation();
    const {
        control,
        formState: {errors, touchedFields, dirtyFields},
    } = useFormContext();
    const isTouched = touchedFields?.[id] || dirtyFields?.[id];
    const isValid = isTouched && !errors?.[id];
    const isInvalid = isTouched && errors?.[id];
    return (
        <div>
            <Typography color="gray-800" as="label" htmlFor={id}>
                {customLabelContents
                    ? customLabelContents
                    : t(`${translationScope}.${label}`)}
            </Typography>
            <Controller
                control={control}
                name={id}
                rules={registerOptions as RegisterOptions<FieldValues, string>}
                // Using controller due to onChange types mismatch, could also customize other things here
                render={({field: {onChange, onBlur, ref, value}}) => {
                    /**
                     * Selections are turned into a comma separated string, but need to be turned back into an options array for the component
                     */
                    const selectedValues =
                        typeof value === 'string' ? value?.split(',') : [];
                    const defaultSelected = values?.filter(
                        (option) => selectedValues?.includes(option?.value),
                    );
                    return (
                        <Typeahead
                            options={values}
                            id={id}
                            isValid={!!isValid}
                            isInvalid={!!isInvalid}
                            onChange={(value: FormSelectOption[]) => {
                                const joinedValues = value
                                    ?.map((option) => option?.value)
                                    ?.join(',');
                                onChange(joinedValues);
                            }}
                            onBlur={onBlur}
                            ref={ref}
                            placeholder={placeholder ?? ''}
                            paginationText={t(
                                `${TranslationScopes.LABELS}.${Labels.MORE_RESULTS}`,
                            )}
                            multiple={multiple}
                            defaultSelected={defaultSelected}
                        />
                    );
                }}
            />
            {infoLabel ? (
                <FormFeedback type={STATUS_TYPE.INFO}>
                    {t(`${TranslationScopes.LABELS}.${infoLabel}`)}
                </FormFeedback>
            ) : null}
            {errors?.[id]?.message ? (
                <FormFeedback type={STATUS_TYPE.DANGER} role="alert">
                    {t(`${TranslationScopes.ERRORS}.${errors?.[id]?.message}`)}
                </FormFeedback>
            ) : null}
        </div>
    );
}
