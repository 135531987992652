/**
 *
 * @param key String separated path to target
 * @param object Any object you want to traverse
 * @returns Found value or undefined
 */
export function getNestedProperty<ObjectType extends object>(
    key: string,
    object: ObjectType,
): ObjectType | undefined {
    const path = key?.split('.');
    if (!path) {
        return undefined;
    }
    let current = object;
    for (const item of path) {
        current = current?.[item as keyof typeof current];
    }
    return current;
}
