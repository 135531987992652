import {Languages} from "@models/languages.enum.ts";
import {FormSteps} from "@models/form-steps.enum.ts";
import {DocumentTypes} from "@models/document-types.enum.ts";
import {EducationTypes} from "@models/education-types.enum.ts";
import {StudyGroups} from "@models/study-groups.enum.ts";

export enum TranslationScopes {
    STUDY_FIELDS = 'STUDY_FIELDS',
    MODULE_FIELDS = 'MODULE_FIELDS',
    SUBJECT_FIELDS = 'SUBJECT_FIELDS',
    VIDEO_FIELDS = 'VIDEO_FIELDS',
    EDUCATION_TYPES = 'EDUCATION_TYPES',
    STUDY_GROUPS = 'STUDY_GROUPS',
    DOCUMENT_TYPES = 'DOCUMENT_TYPES',
    DASHBOARD = 'DASHBOARD',
    LANGUAGES = 'LANGUAGES',
    LABELS = 'LABELS',
    STEPS = 'STEPS',
    TITLES = 'TITLES',
    ACTIONS = 'ACTIONS',
    LOGIN_FIELDS = 'LOGIN_FIELDS',
    ERRORS = 'ERRORS',
}

export enum LoginFormFields {
    NAME = 'name',
    EMAIL = 'email',
    CODE = 'code',
}

export enum DashboardFields {
    STATUS = 'status',
    NOTIFICATIONS = 'notifications',
    COURSES_TITLE = 'courses_title',
    STUDY_PROGRAMMES_TITLE = 'study_programmes_title',
    STUDY_PROGRAMMES = 'study_programmes',
    CREATE_NEW_STUDY_PROGRAMME = 'create_new_study_programme',
    SCHEDULE = 'schedule',
    ECTS_CREDITS = 'ects_credits',
    ALL_COURSES = 'all_courses',
    ALL_STUDY_PROGRAMMES = 'all_study_programmes',
    DRAFT = 'draft',
    PUBLISHED = 'published',
}

export enum StudyFields {
    ID = 'id',
    NAME = 'name',
    CODE = 'code',
    STUDY_PROG_INTRO_LANG = 'introduction_language',
    INSTRUCTION_LANGUAGE = 'language',
    TOTAL_HOURS = 'total_length',
    TYPE = 'type',
    START_DATE = 'start_date',
    END_DATE = 'end_date',
    GROUP = 'group',
    TARGET_AUDIENCE = 'target_group',
    OBJECTIVE = 'purpose',
    LEARNING_OUTCOME = 'outcome',
    PREREQUISITES = 'prerequisites',
    ENVIRONMENT_DESCRIPTION = 'environment_description',
    STUDY_MATERIALS = 'study_materials',
    LECTURERS = 'lecturer_id',
    STATUS = 'status',
    HEAD_OF_TRAINING = 'head_of_training_id',
    REGISTRATION_START_DATE = 'enrollment_start_date',
    REGISTRATION_END_DATE = 'enrollment_end_date',
    COMPLETION_REQUIREMENTS = 'curr_completion_requirements',
    COMPLETION_DOCUMENT = 'certification',
    CONT_EDUCATION_COST = 'price',
    ADDITIONAL_PRICING_INFO = 'pricing_description',
    VIDEOS = 'curriculum_videos',
    VIDEO = 'curr_introductory_video',
    MODULES = 'curriculum_modules',
    MODULE = 'curr_module',
    COURSES = 'subjects',
    COURSE = 'curr_course',
}

export enum VideoFields {
    LINK = 'video_url',
    TITLE = 'video_title',
    ALT = 'video_alt',
}

export enum ModuleFields {
    NAME = 'name',
    OBJECTIVE = 'description',
}

export enum SubjectFields {
    MODULE = 'module_id',
    NAME = 'name',
    CODE = 'code',
    START_DATE = 'start_date',
    END_DATE = 'end_date',
    LECTURE_HOURS = 'study_general_length',
    PRACTICAL_HOURS = 'practical_work_length',
    INDEPENDENT_HOURS = 'independent_work_length',
    TOTAL_HOURS = 'total_length',
    ECTS_CREDITS = 'eap',
    INSTRUCTION_LANGUAGE = 'language',
    TARGET_AUDIENCE = 'target_audience',
    OBJECTIVE = 'purpose',
    LEARNING_OUTCOME = 'description',
    PREREQUISITES = 'prerequisites',
    PREREQUISITE_COURSES = 'subj_prerequisite_courses',
    LOCATION = 'study_location',
    LECTURER = 'lectors',
    LECTURE_ROOMS = 'subject_rooms',
}

export enum Labels {
    ADDITIONAL_PRICING_INFO_PLACEHOLDER = 'additional_pricing_info_placeholder',
    ADD_NUMBER = 'add_number',
    COMPLETION_DOCUMENT_PLACEHOLDER = 'completion_document_placeholder',
    COMPLETION_REQUIREMENTS_PLACEHOLDER = 'completion_requirements_placeholder',
    COURSE = 'course',
    COURSE_INFO_TITLE = 'subject_info_title',
    COURSE_OBJECTIVES_TITLE = 'couse_objectives_title',
    COURSE_ORGANIZATION_TITLE = 'course_organization_title',
    DATA_PARTIALLY_INVALID = 'data_partially_invalid',
    DATA_PARTIALLY_INVALID_DESCRIPTION = 'data_partially_invalid_description',
    DATA_SUBMITTED = 'data_submitted',
    DATE_PLACEHOLDER = 'date_placeholder',
    ECTS_CREDITS = 'eap',
    GROUP_PLACEHOLDER = 'group_placeholder',
    HOURS_TOTAL_DESCRIPTION = 'hours_total_description',
    INDEPENDENT_HOURS = 'independent_hours',
    LANGUAGE_PLACEHOLDER = 'language_placeholder',
    LEARNING_OUTCOME_PLACEHOLDER = 'learning_outcome_placeholder',
    LECTURE_HOURS = 'lecture_hours',
    LOCATION_PLACEHOLDER = 'location_placeholder',
    LOGIN_AUTHENTICATION = 'login_authentication',
    LOGIN_AUTH_EMAIL = 'login_auth_email',
    LOGIN_AUTH_ID = 'login_auth_id',
    LOGIN_LIST_DESCRIPTION = 'login_list_description',
    LOGIN_LIST_FIRST = 'login_list_first',
    LOGIN_LIST_SECOND = 'login_list_second',
    LOGIN_LIST_THIRD = 'login_list_third',
    MODULE = 'module',
    MORE_RESULTS = 'more_results',
    NEXT_STEP = 'next_step',
    OBJECTIVE_PLACEHOLDER = 'objective_placeholder',
    OPTIONAL = 'optional',
    PRACTICAL_HOURS = 'practical_work_length',
    PREREQUISITES_PLACEHOLDER = 'prequisites_placeholder',
    PREREQUISITE_COURSES_PLACEHOLDER = 'prequisite_courses_placeholder',
    PROGRESS = 'progress',
    QUESTIONS_CONTACT = 'questions_contact',
    SEARCH_PLACEHOLDER = 'search_placeholder',
    SELECT_MODULE_PLACEHOLDER = 'subj_module_placeholder',
    TARGET_AUDIENCE_PLACEHOLDER = 'target_audience_placeholder',
    TOTAL_HOURS = 'total_hours',
    VIDEO = 'video_label',
    VIDEOS = 'videos_label',
    VIDEOS_DESCRIPTION = 'videos_description',
}

export enum FormActions {
    BACK = 'back',
    BACK_TO_DASHBOARD = 'back_to_dashboard',
    FORWARD = 'forward',
    SAVE = 'save',
    ADD = 'add',
    REMOVE = 'remove',
    SEND = 'send',
    REDIRECT_AUTH_ID = 'redirect_auth_id',
    SUBMIT = 'submit',
}

export enum FormErrors {
    REQUIRED = 'required',
    EMAIL = 'email',
    URL = 'url',
    NO_MATCHES = 'no_matches',
    SELECT_SEARCH_RESULT = 'select_search_result',
    DATE = 'date',
    ARRAY_EMPTY = 'array_empty',
    ARRAY_REQUIRED = 'array_required',
    UNKNOWN_ERROR = 'unknown_error',
    INVALID_OR_MISSING_EMAIL = 'invalid_or_missing_email',
}

export enum Titles {
    WELCOME = 'welcome',
    AUTHENTICATION = 'authentication',
}

export type TranslationsObject = {
    [TranslationScopes.STUDY_FIELDS]: { [key in StudyFields]?: string };
    [TranslationScopes.SUBJECT_FIELDS]: { [key in SubjectFields]?: string };
    [TranslationScopes.MODULE_FIELDS]: { [key in ModuleFields]: string };
    [TranslationScopes.DOCUMENT_TYPES]: { [key in DocumentTypes]: string };
    [TranslationScopes.VIDEO_FIELDS]: { [key in VideoFields]: string };
    [TranslationScopes.EDUCATION_TYPES]: { [key in EducationTypes]: string };
    [TranslationScopes.STUDY_GROUPS]: { [key in StudyGroups]: string };
    [TranslationScopes.LANGUAGES]: { [key in Languages]: string };
    [TranslationScopes.DASHBOARD]: { [key in DashboardFields]: string };
    [TranslationScopes.LOGIN_FIELDS]: { [key in LoginFormFields]: string };
    [TranslationScopes.ACTIONS]: { [key in FormActions]: string };
    [TranslationScopes.ERRORS]: { [key in FormErrors]: string };
    [TranslationScopes.LABELS]: { [key in Labels]: string };
    [TranslationScopes.STEPS]: { [key in FormSteps]: string };
    [TranslationScopes.TITLES]: { [key in Titles]: string };
};
