import {handleFetch} from "@utils/handleFetch.ts";
import {ApiEndpoints, ApiResponseWithErrors, RequestHeaders, RequestMethods} from "@models/api.model.ts";
import {getCookie} from "@utils/getCookie.ts";
import {authTokenKey} from "@constants/default-values.constant.ts";
import {API_URL} from "@constants/endpoints.constant.ts";
import {StudyFields} from "@models/translations.model.ts";
import {StudyProgramFormData} from "@models/general-form.types.ts";

export const saveStudyProgramForm = async (requestData: StudyProgramFormData, id: number | undefined) => {
    const headers = new Headers();
    headers.append(
        RequestHeaders.AUTHORIZATION,
        `Bearer ${getCookie(authTokenKey)}`,
    );
    headers.append(
        RequestHeaders.CONTENT_TYPE,
        `application/json`,
    );

    let fetchUrl = `${API_URL}${ApiEndpoints.UPDATE_CURRICULUM}`;
    let method = RequestMethods.POST;
    if ( id ) {
        fetchUrl =  `${API_URL}${ApiEndpoints.SAVE_CURRICULUM.replace(':id', id.toString())}`;
        method = RequestMethods.PATCH;

        if ( requestData?.[StudyFields.CODE] ) {
            delete requestData?.[StudyFields.CODE];
        }
    }

    const data = JSON.stringify(requestData);
    return await handleFetch<ApiResponseWithErrors>({
        url: fetchUrl,
        method: method,
        headers,
        data: data,
        multipart: true,
        throwOnError: false,
    })
        .then((res) => {
            if (res?.errors) {
                return {
                    success: false,
                    invalidFields: Object.keys(res?.errors),
                };
            }

            return {
                success: true,
                id: res.data?.id,
            };
        })
        /**
         * Could log exceptions in production here
         */
        .catch(() => {
            return {
                success: false
            };
        });
}
