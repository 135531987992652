import eidLogo from '@assets/logos/eid_logo.webp';
import {FormActions, Labels, Titles, TranslationScopes,} from '@models/translations.model';
import {useTranslation} from 'react-i18next';
import {ActionCard, Typography} from 'taltech-styleguide';
import * as styles from './LoginForm.module.scss';
import {EmailLogin} from './subcomponents/EmailLogin';
import {TranslatedListComponent} from './subcomponents/TranslatedListComponent';

export function LoginForm() {
    const {t} = useTranslation();
    return (
        <>
            <ActionCard>
                <Typography as="h2">
                    {t(`${TranslationScopes.TITLES}.${Titles.WELCOME}`)}
                </Typography>
                <TranslatedListComponent/>
                <Typography as="h2">
                    {t(`${TranslationScopes.TITLES}.${Titles.AUTHENTICATION}`)}
                </Typography>
                <Typography as="p">
                    {t(`${TranslationScopes.LABELS}.${Labels.LOGIN_AUTHENTICATION}`)}
                </Typography>
                <div>
                    <Typography>
                        {t(`${TranslationScopes.LABELS}.${Labels.LOGIN_AUTH_ID}`)}
                    </Typography>
                    <div
                        className={styles.id}
                        title={t(
                            `${TranslationScopes.ACTIONS}.${FormActions.REDIRECT_AUTH_ID}`,
                        )}
                        /**
                         * Currently only serves as an example
                         */
                    >
                        <img className={styles.id__logo} src={eidLogo}
                             alt={t(`${TranslationScopes.LABELS}.${Labels.LOGIN_AUTH_ID}`)}/>
                    </div>
                </div>
                <EmailLogin/>
            </ActionCard>
        </>
    );
}
