import {FormErrors} from './translations.model';
// Custom validators can be defined as functions that get the input and must return validity result
export type ValidatorFn = (
    value: string | unknown[] | unknown,
) => true | (FormErrors | string);

/**
 * Extract arguments into separate type
 */
export type ValidatorFnParameters = Parameters<ValidatorFn>[0];
export type ValidatorFnWithArgs = (...args: unknown[]) => ValidatorFn;
export type ValidatorFnWithArgsParameters = Parameters<ValidatorFnWithArgs>[0];
export type DefaultPatternValidator = {
    pattern: {
        value: RegExp;
        message: FormErrors;
    };
};

// The default validators can be either pattern matching objects or the error message that is shown - example: {required: "Field is required"}
export type DefaultValidatorsMap = {
    [key in FormValidators]?: FormErrors | DefaultPatternValidator;
};

export type CustomValidatorsMap = {
    [key in FormValidators]?: ValidatorFn | ValidatorFnWithArgs;
};

export type Validators = DefaultValidatorsMap & {
    validate: CustomValidatorsMap;
};

/**
 * All implemented field validators
 */
export enum FormValidators {
    REQUIRED = 'required',
    EMAIL = 'email',
    DATE = 'date',
    URL = 'url',
    REQUIRED_OR = 'required_or',
    ARRAY_REQUIRED_OBJECTS = 'array_required_objects',
}

export type FormValidatorWithArgs = {
    validator: FormValidators;
    args: unknown[];
};
