import successIcon from '@assets/icons/success.svg';
import ExternalLink from '@components/common/ExternalLink';
import {questionsContactEmail} from '@constants/default-values.constant';
import {FormSubmitResult} from '@models/general-form.types';
import {FormActions, Labels, TranslationScopes,} from '@models/translations.model';
import {Trans, useTranslation} from 'react-i18next';
import {ActionCard, TTNewButton, TTNewCol, Typography} from 'taltech-styleguide';

/**
 *
 * @param object containing the form submit result and error callback to be used if something was invalid
 * @returns success screen that will get rid of the user's cookie a list of invalid fields and the option to go back to form
 */
export function FormResult({
       errorCallback,
   }: {
    result: FormSubmitResult;
    errorCallback: () => void;
}) {
    const {t} = useTranslation();
    return (
        <TTNewCol size={7}>
            <ActionCard className="mt-4 mb-4">
                <div>
                    <Typography as="h2">
                        <img src={successIcon} alt="" className="me-3"></img>
                        {t(
                            `${TranslationScopes.LABELS}.${Labels.DATA_SUBMITTED}`,
                        )}
                    </Typography>

                    <Typography>
                        {t(
                            `${TranslationScopes.LABELS}.${Labels.NEXT_STEP}`,
                        )}
                    </Typography>
                    <Trans
                        i18nKey={`${TranslationScopes.LABELS}.${Labels.QUESTIONS_CONTACT}`}
                        components={[
                            <ExternalLink
                                key={`error-${questionsContactEmail}`}
                                href={`mailto:${questionsContactEmail}`}
                                openInNewTab={false}
                            ></ExternalLink>,
                        ]}
                        values={{linkContents: questionsContactEmail}}
                    ></Trans>
                </div>
            </ActionCard>
            <TTNewButton onClick={errorCallback}>
                {t(`${TranslationScopes.ACTIONS}.${FormActions.BACK_TO_DASHBOARD}`)}
            </TTNewButton>
        </TTNewCol>
    );
}
