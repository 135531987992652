/**
 *
 * @param key cookie name
 * @param value cookie value
 * @description sets the designated cookie, additionally sets samesite strict and secure options
 */
export function setCookie(key: string, value: string) {
    const expirationDate = new Date();
    /**
     * Making cookie last for at least 10 years, there's no need to expire it for this purpose
     */
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
    const secureString = isSecureContext ? 'secure' : '';
    /**
     * Setting cookie along with some extra security properties
     * secure option is conditional to allow for testing on http websites
     */
    document.cookie = `${key}=${value}; samesite=strict; ${secureString}; expires=${expirationDate.toUTCString()}`;
}
