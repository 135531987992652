import {authTokenKey} from '@constants/default-values.constant';
import {ApiEndpoints, RequestHeaders, RequestMethods,} from '@models/api.model';
import {deleteCookie} from './deleteCookie';
import {getCookie} from './getCookie';
import {handleFetch} from './handleFetch';

/**
 *
 * @param callback function to call after logout query returns
 * @description Queries backend with the api token, deletes cookie and calls callback once query finishes
 */
export function handleLogout(callback?: () => void): void {
    const cookie = getCookie(authTokenKey);
    const headers = new Headers();
    headers.append(RequestHeaders.AUTHORIZATION, `Bearer ${cookie}`);
    headers.append(RequestHeaders.CONTENT_LENGTH, '0');
    /**
     * Perform a logout fetch with auth cookie and trigger callback once it resolves
     */
    handleFetch({
        endpoint: ApiEndpoints.LOG_OUT,
        method: RequestMethods.POST,
        headers,
    })
        /**
         * Nothing to really do with the catch at the moment, as deleting the cookie is already enough
         * Could also use this to leave a trace in the logs in production
         */
        .catch()
        .finally(() => {
            deleteCookie(authTokenKey);
            if (callback) {
                callback?.();
            }
        });
}
