import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import enTranslation from "./assets/i18n/en";
import etTranslation from "./assets/i18n/et";

const resources = {
    en: {
        translation: enTranslation,
    },
    et: {
        translation: etTranslation,
    },
};

i18next.use(initReactI18next).init({
    resources,
    lng: "et",
});

export default i18next;
