/**
 *
 * @param key the cookie you want the value of
 * @returns the value or undefined
 */
export function getCookie(key: string): string | undefined {
    const cookies = document?.cookie;
    if (!cookies) {
        return undefined;
    }
    /**
     * Get all semicolon separated cookies
     */
    for (const cookie of cookies.split(';')) {
        /**
         * Split into name and value
         */
        const [cookieName, cookieValue] = cookie.trim().split('=');
        /**
         * Trim any existing whitespace and compare
         */
        if (cookieName === key) {
            return cookieValue;
        }
    }
    return undefined;
}
