import {
    FormActions,
    FormErrors,
    Labels,
    LoginFormFields,
    ModuleFields,
    StudyFields,
    SubjectFields,
    Titles,
    TranslationScopes,
    TranslationsObject,
    VideoFields,
} from '@models/translations.model';
import {FormSteps} from "@models/form-steps.enum.ts";
import {EducationTypes} from "@models/education-types.enum.ts";
import {Languages} from "@models/languages.enum.ts";
import {StudyGroups} from "@models/study-groups.enum.ts";
import {DocumentTypes} from "@models/document-types.enum.ts";

const enTranslations: TranslationsObject = {
    [TranslationScopes.ACTIONS]: {
        [FormActions.SEND]: 'Send',
        [FormActions.BACK]: 'Back',
        [FormActions.ADD]: 'Add',
        [FormActions.FORWARD]: 'Forward',
        [FormActions.SAVE]: 'Save',
        [FormActions.REDIRECT_AUTH_ID]: 'Redirect to the id card authentication page',
        [FormActions.SUBMIT]: 'Send to head of training',
        [FormActions.REMOVE]: 'Remove',
    },
    [TranslationScopes.DOCUMENT_TYPES]: {
        [DocumentTypes.CERTIFICATE]: 'Certificate',
        [DocumentTypes.COMPLETION_CERTIFICATE]: 'Certificate of completion',
        [DocumentTypes.ATTENDANCE_CERTIFICATE]: 'Certificate of attendance',
    },
    [TranslationScopes.EDUCATION_TYPES]: {
        [EducationTypes.MICRO]: 'Micro-credential program',
        [EducationTypes.CONTINUING_EDUCATION]: 'Continuing education programmes',
    },
    [TranslationScopes.ERRORS]: {
        [FormErrors.REQUIRED]: 'Required field',
        [FormErrors.EMAIL]: 'Invalid email',
        [FormErrors.URL]: 'Invalid URL',
        [FormErrors.NO_MATCHES]: 'No matches found',
        [FormErrors.SELECT_SEARCH_RESULT]: 'Select from the search results',
        [FormErrors.DATE]: 'Invalid date',
        [FormErrors.ARRAY_REQUIRED]: 'There are empty required fields in the list',
        [FormErrors.ARRAY_EMPTY]: 'The list is empty',
        [FormErrors.UNKNOWN_ERROR]: 'Something went wrong',
        [FormErrors.INVALID_OR_MISSING_EMAIL]: 'Email is invalid or missing from the HR information system',
    },
    [TranslationScopes.LABELS]: {
        [Labels.ADDITIONAL_PRICING_INFO_PLACEHOLDER]: 'Describe how the continuing education is priced',
        [Labels.ADD_NUMBER]: 'Add number',
        [Labels.COMPLETION_DOCUMENT_PLACEHOLDER]: 'Select document type',
        [Labels.COMPLETION_REQUIREMENTS_PLACEHOLDER]: 'Describe the assessment criteria',
        [Labels.COURSE]: 'course',
        [Labels.COURSE_INFO_TITLE]: 'Course basic information',
        [Labels.COURSE_OBJECTIVES_TITLE]: 'Course objectives',
        [Labels.COURSE_ORGANIZATION_TITLE]: 'Course organization',
        [Labels.DATA_PARTIALLY_INVALID]: 'The form has unfilled fields',
        [Labels.DATA_PARTIALLY_INVALID_DESCRIPTION]: 'There are deficiencies in the form, which is why it could not be submitted. In order to successfully submit the personal data form, all mandatory fields must be filled in correctly. Please go over the following fields and try to submit again:',
        [Labels.DATA_SUBMITTED]: 'Thank you! The data has been submitted!',
        [Labels.DATE_PLACEHOLDER]: 'Choose date',
        [Labels.ECTS_CREDITS]: 'Volume in ECTS',
        [Labels.GROUP_PLACEHOLDER]: 'Choose study programme group',
        [Labels.HOURS_TOTAL_DESCRIPTION]: 'Total volume is calculated from the volumes of lectures, practical work and independent work.',
        [Labels.INDEPENDENT_HOURS]: 'Volume of independent work (ac h)',
        [Labels.LANGUAGE_PLACEHOLDER]: 'Choose language',
        [Labels.LEARNING_OUTCOME_PLACEHOLDER]: 'Describe learning outcomes',
        [Labels.LECTURE_HOURS]: 'Volume of lecture hours (ac h)',
        [Labels.LOCATION_PLACEHOLDER]: 'Describe where the courses will take place',
        [Labels.LOGIN_AUTHENTICATION]: 'You must authenticate yourself before filling out the form. You can do it with an ID card or by e-mail.',
        [Labels.LOGIN_AUTH_EMAIL]: 'Send confirmation code to email address:',
        [Labels.LOGIN_AUTH_ID]: 'Authenticate with your ID card:',
        [Labels.LOGIN_LIST_DESCRIPTION]: `Before filling out the personal data form, we recommend that you spare some time and find the necessary documents. You will need:\n
      <0></0>
      Don't worry if you don't have these documents at hand right away, because you can leave the form unfinished and continue later until you are ready to press "Submit".
      `,
        [Labels.LOGIN_LIST_FIRST]: 'a photo of yourself',
        [Labels.LOGIN_LIST_SECOND]: 'a proof of identity document',
        [Labels.LOGIN_LIST_THIRD]: 'a copy of the document representing your highest obtained education level',
        [Labels.MODULE]: 'module',
        [Labels.MORE_RESULTS]: 'Show more results',
        [Labels.OBJECTIVE_PLACEHOLDER]: 'Describe course objectives',
        [Labels.OPTIONAL]: 'optional',
        [Labels.PRACTICAL_HOURS]: 'Volume of practical work (ac h)',
        [Labels.PREREQUISITES_PLACEHOLDER]: 'Describe course prerequisites',
        [Labels.PREREQUISITE_COURSES_PLACEHOLDER]: 'Select the course(s) that are prerequisites for this course.',
        [Labels.PROGRESS]: 'Progress',
        [Labels.QUESTIONS_CONTACT]: 'If you have any questions, contact <0>{{linkContents}}</0>.',
        [Labels.SEARCH_PLACEHOLDER]: 'Search by keyword, document, employee',
        [Labels.SELECT_MODULE_PLACEHOLDER]: 'Choose module',
        [Labels.TARGET_AUDIENCE_PLACEHOLDER]: 'Describe target audience',
        [Labels.TOTAL_HOURS]: 'Total course volume (ac h)',
        [Labels.VIDEO]: 'Video',
        [Labels.VIDEOS]: 'Add Study Program Introduction Video',
        [Labels.VIDEOS_DESCRIPTION]: 'The video is used to promote the continuing education programme online.',

    },
    [TranslationScopes.LANGUAGES]: {
        [Languages.EN]: 'English',
        [Languages.ET]: 'Estonian',
        [Languages.RU]: 'Russian',
    },
    [TranslationScopes.LOGIN_FIELDS]: {
        [LoginFormFields.EMAIL]: 'E-mail',
        [LoginFormFields.CODE]: 'Code',
    },
    [TranslationScopes.MODULE_FIELDS]: {
        [ModuleFields.NAME]: 'Module name',
        [ModuleFields.OBJECTIVE]: 'Module objective',
    },
    [TranslationScopes.STEPS]: {
        [FormSteps.MODULES]: 'Modules',
        [FormSteps.GENERAL_INFO]: 'Basic info',
        [FormSteps.OBJECTIVES]: 'Objectives',
        [FormSteps.COURSES]: 'Courses',
        [FormSteps.MANAGEMENT]: 'Management',
        [FormSteps.CONDITIONS]: 'Conditions',
    },
    [TranslationScopes.STUDY_FIELDS]: {
        [StudyFields.NAME]: 'Study programme title',
        [StudyFields.CODE]: 'Study programme code',
        [StudyFields.STUDY_PROG_INTRO_LANG]: 'Study programm introduction language',
        [StudyFields.INSTRUCTION_LANGUAGE]: 'Language of instruction',
        [StudyFields.TYPE]: 'Type',
        [StudyFields.START_DATE]: 'Start date (DD.MM.YYYY)',
        [StudyFields.END_DATE]: 'End date (DD.MM.YYYY)',
        [StudyFields.GROUP]: 'Study programme group',
        [StudyFields.TARGET_AUDIENCE]: 'Target audience',
        [StudyFields.OBJECTIVE]: 'Program objective',
        [StudyFields.LEARNING_OUTCOME]: 'Learning outcomes',
        [StudyFields.PREREQUISITES]: 'Pre-conditions for the commencement of studies',
        [StudyFields.LECTURERS]: 'Lecturers',
        [StudyFields.HEAD_OF_TRAINING]: 'Head of Training',
        [StudyFields.ENVIRONMENT_DESCRIPTION]: 'Description of the learning environment',
        [StudyFields.STUDY_MATERIALS]: 'List of study materials',
        [StudyFields.REGISTRATION_START_DATE]: 'Registration permitted from (DD.MM.YYYY)',
        [StudyFields.REGISTRATION_END_DATE]: 'Deadline for registration (DD.MM.YYYY)',
        [StudyFields.COMPLETION_REQUIREMENTS]: 'Requirements for completion',
        [StudyFields.COMPLETION_DOCUMENT]: 'Document issued on completion',
        [StudyFields.CONT_EDUCATION_COST]: 'Continuing education programme cost (€)',
        [StudyFields.ADDITIONAL_PRICING_INFO]: 'Additional information about the price',
    },
    [TranslationScopes.STUDY_GROUPS]: {
        [StudyGroups.GENERAL_CURRICULUM]: 'general_curriculum',
        [StudyGroups.EDUCATIONAL]: 'educational',
        [StudyGroups.LIBERAL_ARTS]: 'liberal_arts_in_humanities',
        [StudyGroups.SOCIAL_SCIENCES]: 'social_sciences_journalism_and_information_dissemination',
        [StudyGroups.BUSINESS_ADMIN]: 'business_administration_and_law',
        [StudyGroups.NATURAL_SCIENCES]: 'natural_sciences_mathematics_and_statistics',
        [StudyGroups.IC_TECHNOLOGIES]: 'information_and_communication_technologies',
        [StudyGroups.ENGINEERING]: 'engineering_manufacturing_and_construction',
        [StudyGroups.AGRICULTURE]: 'agriculture_forestry_fisheries_and_veterinary',
        [StudyGroups.HEALTH_AND_WELFARE]: 'health_and_welfare',
        [StudyGroups.SERVICES]: 'services',
    },
    [TranslationScopes.SUBJECT_FIELDS]: {
        [SubjectFields.MODULE]: 'Module',
        [SubjectFields.CODE]: 'Course code',
        [SubjectFields.NAME]: 'Course title',
        [SubjectFields.START_DATE]: 'Course start date (DD.MM.YYYY)',
        [SubjectFields.END_DATE]: 'Course end date (DD.MM.YYYY)',
        [SubjectFields.INSTRUCTION_LANGUAGE]: 'Language of instruction',
        [SubjectFields.TARGET_AUDIENCE]: 'Target audience',
        [SubjectFields.OBJECTIVE]: 'Objectives',
        [SubjectFields.LEARNING_OUTCOME]: 'Learning outcomes',
        [SubjectFields.PREREQUISITES]: 'Prerequisites',
        [SubjectFields.PREREQUISITE_COURSES]: 'Prerequisite courses',
        [SubjectFields.LOCATION]: 'Location',
    },
    [TranslationScopes.TITLES]: {
        [Titles.WELCOME]: 'Welcome!',
        [Titles.AUTHENTICATION]: 'Authentication',
    },
    [TranslationScopes.VIDEO_FIELDS]: {
        [VideoFields.LINK]: 'Video link',
        [VideoFields.TITLE]: 'Video title',
        [VideoFields.ALT]: 'Video alt text',
    }
}

export default enTranslations;
