.toggleButton {
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 16px;
  padding-right: 16px;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--bs-primary);

  &[aria-expanded="true"] {
    background-color: var(--bs-gray-300);
  }
}
