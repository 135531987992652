import {API_URL} from '@constants/endpoints.constant';
import {ContentTypes, HandleFetchOptions, RequestMethods, RequestOptions,} from '@models/api.model';

/**
 *
 * @param url a string value or null for the API_URL variable
 * @param endpoint one of the ApiEndpoints choices or null if using a custom url
 * @param method one of the possible HTTP methods
 * @param headers HTTP request headers
 * @param data any data you want to pass in as body
 * @param multipart whether the request is a multipart/form-data type
 * @param throwOnError whether the request should throw if backend responded with an error
 * @param includeStatus whether the request should include status code
 * @returns promise that resolves with the type you passed into generic or an object containing error
 */
export function handleFetch<ExpectedReturn = unknown>({
                                                          url,
                                                          endpoint,
                                                          method,
                                                          headers,
                                                          data,
                                                          multipart,
                                                          throwOnError = true,
                                                          includeStatus = !throwOnError || false,
                                                      }: HandleFetchOptions): Promise<ExpectedReturn> {
    const options = {
        headers,
        method,
    } as RequestOptions;
    if (method !== RequestMethods.GET) {
        /**
         * If headers argument is an instance of headers, then use the provided get method, otherwise assume it's an object
         */
        const contentLength =
            headers instanceof Headers
                ? headers?.get('Content-Length')
                : Object.entries(headers ?? {})?.filter(
                    ([key]) => key === 'Content-Length',
                )?.[1];
        const emptyBody = contentLength === '0';
        /**
         * Throw if body was unintentionally left empty
         */
        if (!data && !emptyBody) {
            throw new Error(`Missing request body`);
        } else if (data) {
            /**
             * multipart data doesn't need stringifying
             */
            options.body = multipart ? (data as FormData) : JSON.stringify(data);
        }
    }
    return fetch(`${url ?? API_URL}${endpoint ?? ''}`, options).then((res) => {
        /**
         * Trigger catch handlers if request errored
         */
        if (!res?.ok && throwOnError) {
            throw new Error(`${res.status}`);
        }
        const responseContentType = res?.headers?.get('content-type');
        /**
         * Convert to json only if the response is a json type
         */
        if (responseContentType?.includes(ContentTypes.JSON)) {
            if (!res?.ok || includeStatus) {
                /**
                 * Include the status on error responses
                 */
                return res.json().then((json) => ({data: json, status: res.status}));
            }
            return res.json();
        }

        if (includeStatus) {
            return res.text().then((text) => ({data: text, status: res.status}));
        }
        return res.text();
    });
}
