import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ActionCard, Typography} from 'taltech-styleguide';
import {FormSteps} from '@models/form-steps.enum';
import {Labels, StudyFields, TranslationScopes} from '@models/translations.model';
import {FormValidators} from '@models/validation.model';
import {getValidators} from '@validation/getValidators';
import FormInput from '@components/Form/FormInput/FormInput';
import FormSelect from '@components/Form/FormSelect/FormSelect.tsx';
import FormRadioButton from '@components/Form/FormRadioButton/FormRadioButton.tsx';
import FormDateRangePicker from '@components/Form/FormDateRangePicker/FormDateRangePicker.tsx';
import {EducationTypes} from "@models/education-types.enum.ts";
import {Languages} from "@models/languages.enum.ts";

export function GeneralInfoStep() {
    const {t} = useTranslation();

    const languageOptions = useMemo(
        () => Object.values(Languages)?.map((lang) => ({
            label: t(`${TranslationScopes.LANGUAGES}.${lang}`),
            value: lang
        })),
        [t]
    );

    return (
        <ActionCard>
            <Typography as="h2">
                {t(`${TranslationScopes.STEPS}.${FormSteps.GENERAL_INFO}`)}
            </Typography>

            <FormInput
                id={StudyFields.NAME}
                label={StudyFields.NAME}
                translationScope={TranslationScopes.STUDY_FIELDS}
                registerOptions={{
                    ...getValidators([FormValidators.REQUIRED])
                }}
            />
            <FormInput
                id={StudyFields.CODE}
                label={StudyFields.CODE}
                translationScope={TranslationScopes.STUDY_FIELDS}
                registerOptions={{
                    ...getValidators([FormValidators.REQUIRED])
                }}
            />
            <FormSelect
                id={StudyFields.STUDY_PROG_INTRO_LANG}
                label={StudyFields.STUDY_PROG_INTRO_LANG}
                registerOptions={{
                    ...getValidators([FormValidators.REQUIRED])
                }}
                values={languageOptions}
                placeholder={t(
                    `${TranslationScopes.LABELS}.${Labels.LANGUAGE_PLACEHOLDER}`
                )}
                translationScope={TranslationScopes.STUDY_FIELDS}/>
            <FormSelect
                id={StudyFields.INSTRUCTION_LANGUAGE}
                label={StudyFields.INSTRUCTION_LANGUAGE}
                registerOptions={{
                    ...getValidators([FormValidators.REQUIRED])
                }}
                values={languageOptions}
                placeholder={t(
                    `${TranslationScopes.LABELS}.${Labels.LANGUAGE_PLACEHOLDER}`
                )}
                translationScope={TranslationScopes.STUDY_FIELDS}/>
            <FormRadioButton
                id={StudyFields.TYPE}
                label={StudyFields.TYPE}
                registerOptions={{
                    ...getValidators([FormValidators.REQUIRED]),
                }}
                values={[EducationTypes.MICRO, EducationTypes.CONTINUING_EDUCATION]}
                translationScope={TranslationScopes.EDUCATION_TYPES}/>

            <FormDateRangePicker
                id={`${StudyFields.START_DATE}-${StudyFields.END_DATE}`}
                firstDateId={StudyFields.START_DATE}
                secondDateId={StudyFields.END_DATE}
                registerOptions={{
                    ...getValidators([FormValidators.REQUIRED])
                }}
                translationScope={TranslationScopes.STUDY_FIELDS}
            />
        </ActionCard>
    );
}
