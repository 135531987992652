import {FormRadioButtonProps} from '@models/form-input.types';
import {TranslationScopes} from '@models/translations.model';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {CustomInput, FormFeedback, STATUS_TYPE} from 'taltech-styleguide';
import * as styles from './FormRadioButton.module.scss';

export default function FormRadioButton<InputId extends string>({
                                                                    id,
                                                                    values,
                                                                    registerOptions,
                                                                    translationScope,
                                                                    infoLabel,
                                                                }: FormRadioButtonProps<InputId>) {
    const {t} = useTranslation();
    const {
        register,
        formState: {errors},
    } = useFormContext();

    return (
        <div>
            <fieldset className={styles.fieldset}>
                <legend className={`${styles.radio__legend}`}>
                    {t(`${TranslationScopes.STUDY_FIELDS}.${id}`)}
                </legend>
                <div className={styles.radio__wrapper}>
                    {values?.map((value, i) => {
                        return (
                            <CustomInput
                                {...register(id, registerOptions)}
                                type="radio"
                                id={id}
                                key={`${id}-${value}-${i}`}
                                value={value}
                                label={t(`${translationScope}.${value}`)}
                            />
                        );
                    })}
                </div>
            </fieldset>
            {infoLabel ? (
                <FormFeedback type={STATUS_TYPE.INFO}>
                    {t(`${TranslationScopes.LABELS}.${infoLabel}`)}
                </FormFeedback>
            ) : null}
            {errors?.[id]?.message ? (
                <FormFeedback type={STATUS_TYPE.DANGER} role="alert">
                    {t(`${TranslationScopes.ERRORS}.${errors?.[id]?.message}`)}
                </FormFeedback>
            ) : null}
        </div>
    );
}
