import {useCallback, useState} from 'react';
import {useTranslation} from "react-i18next";
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider,} from 'react-router-dom';
import {ConfigProvider, ErrorBoundary, Header, TTNewContainer,} from 'taltech-styleguide';
import 'taltech-styleguide/index.css';
import i18next from './i18n';
import {authTokenKey} from '@constants/default-values.constant';
import {authenticatedLoader} from './loaders/authenticatedLoader';
import {dashboardLoader} from './loaders/dashboardLoader.ts';
import {studyProgramLoader} from "./loaders/studyProgramLoader.ts";
import {Labels, TranslationScopes} from "@models/translations.model.ts";
import {Routes} from '@models/routes.enum';
import {LanguagesShort} from "@models/languages.enum.ts";
import {getCookie} from '@utils/getCookie';
import {handleLogout} from '@utils/handleLogout';
import {Layout} from './components/Layout';
import {LoginForm} from './components/LoginForm/LoginForm';
import {Dashboard} from '@components/Dashboard/Dashboard.tsx';
import {StudyProgramForm} from "@components/StudyProgramForm/StudyProgramForm.tsx";

const AppRouter = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route
                path={Routes.INDEX}
                element={<Dashboard/>}
                loader={dashboardLoader}
            />
            <Route
                path={Routes.LOGIN}
                element={<LoginForm/>}
                loader={authenticatedLoader}
            />
            <Route
                path={Routes.NEW_CURRICULUM}
                element={<StudyProgramForm/>}
                loader={studyProgramLoader}
            />
            <Route
                path={`${Routes.CURRICULUM}/:curriculumId`}
                element={<StudyProgramForm/>}
                loader={studyProgramLoader}
            />
        </>,
    ),
);

function App() {
    const {t} = useTranslation();
    const [activeLang, setActiveLang] = useState<LanguagesShort>(
        i18next?.language as LanguagesShort,
    );
    const isLoggedIn = !!getCookie(authTokenKey);
    const onLanguageSwitch = useCallback(() => {
        /**
         * Header currently only allows switching between two choices, so we get the one that isn't active
         */
        const newLang = activeLang === LanguagesShort.ET ? LanguagesShort.EN : LanguagesShort.ET;
        i18next.changeLanguage(newLang, () => setActiveLang(newLang));
    }, [activeLang]);

    const onLogout = useCallback(() => {
        /**
         * Don't display logout option if user isn't logged in
         */
        if (isLoggedIn) {
            handleLogout(() => window?.location?.reload());
        }
    }, [isLoggedIn]);

    return (
        <>
            <ConfigProvider locale={activeLang as LanguagesShort}>
                <ErrorBoundary>
                    <Header
                        profile={{
                            onLanguageSwitch,
                            profile: {
                                firstName: 'Katrin',
                                lastName: 'Rohi'
                            },
                            onLogout: isLoggedIn ? onLogout : undefined,
                        }}
                        search={{
                            placeholder: t(`${TranslationScopes.LABELS}.${Labels.SEARCH_PLACEHOLDER}`)
                        }}
                        help
                        links={[
                            {
                                active: true,
                                children: 'ÕPPEINFOSÜSTEEM',
                                items: [
                                    {
                                        children: 'Töökorraldus',
                                        items: [
                                            {
                                                children: 'Pealkiri',
                                                items: [
                                                    {
                                                        children: 'Menüüpunkt',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Menüüpunkt',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Menüüpunkt',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Menüüpunkt',
                                                        target: '_blank',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Menüüpunkt',
                                                        to: '/menuupunkt'
                                                    }
                                                ]
                                            }
                                        ],
                                        to: ''
                                    },
                                    {
                                        children: 'Dokumendid',
                                        items: [
                                            {
                                                children: 'Mallid',
                                                items: [
                                                    {
                                                        children: 'Dokumendimallid',
                                                        to: '/dokumendimallid'
                                                    },
                                                    {
                                                        children: 'Vormid',
                                                        to: '/vormid'
                                                    },
                                                    {
                                                        children: 'Slaidipõhjad',
                                                        to: '/slaidipõhjad'
                                                    },
                                                    {
                                                        children: 'Juhendid',
                                                        to: '/juhendid'
                                                    }
                                                ]
                                            },
                                            {
                                                children: 'Registrid',
                                                items: [
                                                    {
                                                        children: 'Lähetused',
                                                        to: '/lahetused'
                                                    },
                                                    {
                                                        children: 'Lepingud',
                                                        to: '/peingud'
                                                    },
                                                    {
                                                        children: 'Kirjad',
                                                        to: '/kirjad'
                                                    },
                                                    {
                                                        children: 'Volikirjad',
                                                        to: '/volikirjad'
                                                    },
                                                    {
                                                        children: 'Seotud isikud kes peavad palju tööd tegema',
                                                        to: '/seotud-isikud'
                                                    }
                                                ]
                                            },
                                            {
                                                children: 'Asjaajamine',
                                                items: [
                                                    {
                                                        children: 'Eeskiri',
                                                        to: '/eesikir'
                                                    },
                                                    {
                                                        children: 'Dokumentide loetelu',
                                                        to: '/dokumentide-loetelu'
                                                    },
                                                    {
                                                        children: 'Korralduste liigitus',
                                                        to: '/korralduste-liigitus'
                                                    },
                                                    {
                                                        children: 'Käskkirjade liigitus',
                                                        to: '/kaskkirjade-liigitus'
                                                    },
                                                    {
                                                        children: 'Lepingute liigitus',
                                                        to: '/lepingute-liigitus'
                                                    }
                                                ]
                                            },
                                            {
                                                children: 'Õigusaktid',
                                                items: [
                                                    {
                                                        children: 'Määrused',
                                                        to: '/maarused'
                                                    },
                                                    {
                                                        children: 'Korraldused',
                                                        to: '/korraldused'
                                                    },
                                                    {
                                                        children: 'Otsused',
                                                        to: '/otsused'
                                                    },
                                                    {
                                                        children: 'Käskkirjad',
                                                        to: '/kaskkirjad'
                                                    }
                                                ]
                                            },
                                            {
                                                children: 'Valdkonnad',
                                                items: [
                                                    {
                                                        children: 'Kantselei',
                                                        to: '/kantselei'
                                                    },
                                                    {
                                                        children: 'Arhiiv',
                                                        to: '/arhiiv'
                                                    },
                                                    {
                                                        children: 'Post',
                                                        to: '/post'
                                                    },
                                                    {
                                                        children: 'Kantselei',
                                                        to: '/kantselei'
                                                    },
                                                    {
                                                        children: 'Arhiiv',
                                                        to: '/arhiiv'
                                                    },
                                                    {
                                                        children: 'Post',
                                                        to: '/post'
                                                    }
                                                ]
                                            },
                                            {
                                                children: 'Jada',
                                                items: [
                                                    {
                                                        children: 'Kantselei',
                                                        to: '/kantselei'
                                                    },
                                                    {
                                                        children: 'Arhiiv',
                                                        to: '/arhiiv'
                                                    },
                                                    {
                                                        children: 'Post',
                                                        to: '/post'
                                                    },
                                                    {
                                                        children: 'Kantselei',
                                                        to: '/kantselei'
                                                    },
                                                    {
                                                        children: 'Arhiiv',
                                                        to: '/arhiiv'
                                                    },
                                                    {
                                                        children: 'Post',
                                                        to: '/post'
                                                    }
                                                ]
                                            },
                                            {
                                                items: [
                                                    {
                                                        children: 'Kantselei',
                                                        to: '/kantselei'
                                                    },
                                                    {
                                                        children: 'Arhiiv',
                                                        to: '/arhiiv'
                                                    },
                                                    {
                                                        children: 'Post',
                                                        to: '/post'
                                                    },
                                                    {
                                                        children: 'Kantselei',
                                                        to: '/kantselei'
                                                    },
                                                    {
                                                        children: 'Arhiiv',
                                                        to: '/arhiiv'
                                                    },
                                                    {
                                                        children: 'Post',
                                                        to: '/post'
                                                    }
                                                ]
                                            }
                                        ],
                                        to: '',
                                        topLinks: [
                                            {
                                                children: 'Menüüpunkt',
                                                to: '/menuupunkt'
                                            },
                                            {
                                                children: 'Menüüpunkt2',
                                                to: '/menuupunkt'
                                            }
                                        ]
                                    },
                                    {
                                        children: 'Teenused',
                                        items: [
                                            {
                                                children: 'Rahvusvaheline koostöö',
                                                items: [
                                                    {
                                                        children: 'Projektid',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Partnerülikoolid',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Külaliste vastuvõtt',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Võrgustikud',
                                                        to: '/menuupunkt'
                                                    }
                                                ]
                                            },
                                            {
                                                children: 'It teenused',
                                                items: [
                                                    {
                                                        children: 'Teenuste nimekiri',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Infoturbepoliitika',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Sõnaraamat',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Lisa tellimus / teatis',
                                                        to: '/menuupunkt'
                                                    }
                                                ]
                                            },
                                            {
                                                children: 'Personal',
                                                items: [
                                                    {
                                                        children: 'Puhkuse vormistamine',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Töölähetus',
                                                        to: '/menuupunkt'
                                                    }
                                                ]
                                            },
                                            {
                                                children: 'Õpetamine ja õppimine',
                                                items: [
                                                    {
                                                        children: 'Rahvusvaheline mobiilsus',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Haridustehnoloogia',
                                                        to: '/menuupunkt'
                                                    }
                                                ]
                                            },
                                            {
                                                children: 'Kinnisvara haldus',
                                                items: [
                                                    {
                                                        children: 'Parkimine',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Ruumide rent',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Transport',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Läbipääsu õigused',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Koristus / heakord',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Küte ja ventilatsioon',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Lisa tellimus / teatis',
                                                        to: '/menuupunkt'
                                                    }
                                                ]
                                            },
                                            {
                                                children: 'Õigusalane teenindamine',
                                                items: [
                                                    {
                                                        children: 'Kontaktid',
                                                        to: '/menuupunkt'
                                                    }
                                                ]
                                            },
                                            {
                                                children: 'Innovatsioon ja ettevõtlus',
                                                items: [
                                                    {
                                                        children: 'Intellektuaalne omand',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Spin-off ettevõtted',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Start-up',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Projektide elluviimine',
                                                        to: '/menuupunkt'
                                                    }
                                                ]
                                            },
                                            {
                                                children: 'Turunud ja kommunikatsioon',
                                                items: [
                                                    {
                                                        children: 'Teenuste nimekiri',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Bränd',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Meened',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Mante et Manu',
                                                        to: '/menuupunkt'
                                                    }
                                                ]
                                            },
                                            {
                                                children: 'Sportimine',
                                                items: [
                                                    {
                                                        children: 'TalTech spordiklubi',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Sportimisvõimalused',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Üritused Spordihoones',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Sisekorraeeskiri',
                                                        to: '/menuupunkt'
                                                    }
                                                ]
                                            },
                                            {
                                                children: 'Finantsjuhtimine',
                                                items: [
                                                    {
                                                        children: 'Üldinfo',
                                                        to: '/menuupunkt'
                                                    }
                                                ]
                                            },
                                            {
                                                children: 'Dokumendi- ja arhiivihaldus',
                                                items: [
                                                    {
                                                        children: 'Kantselei',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Dukemndihaldussüsteem',
                                                        to: '/menuupunkt'
                                                    }
                                                ]
                                            }
                                        ],
                                        to: ''
                                    },
                                    {
                                        children: 'Personal',
                                        to: '/personal'
                                    },
                                    {
                                        children: 'Koolitused',
                                        items: [
                                            {
                                                children: 'Pealkiri',
                                                items: [
                                                    {
                                                        children: 'Menüüpunkt',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Menüüpunkt',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Menüüpunkt',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Menüüpunkt',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Menüüpunkt',
                                                        to: '/menuupunkt'
                                                    }
                                                ]
                                            },
                                            {
                                                children: 'Pealkiri2',
                                                items: [
                                                    {
                                                        children: 'Menüüpunkt',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Menüüpunkt',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Menüüpunkt',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Menüüpunkt',
                                                        to: '/menuupunkt'
                                                    }
                                                ]
                                            },
                                            {
                                                children: 'Pealkiri3',
                                                items: [
                                                    {
                                                        children: 'Menüüpunkt',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Menüüpunkt',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Menüüpunkt',
                                                        to: '/menuupunkt'
                                                    }
                                                ]
                                            },
                                            {
                                                children: 'Pealkiri4',
                                                items: [
                                                    {
                                                        children: 'Menüüpunkt',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Menüüpunkt',
                                                        to: '/menuupunkt'
                                                    },
                                                    {
                                                        children: 'Menüüpunkt',
                                                        to: '/menuupunkt'
                                                    }
                                                ]
                                            }
                                        ],
                                        to: '',
                                        topLinks: [
                                            {
                                                children: 'Menüüpunkt',
                                                to: '/menuupunkt'
                                            },
                                            {
                                                children: 'Menüüpunkt2',
                                                to: '/menuupunkt'
                                            },
                                            {
                                                children: 'Menüüpunkt3',
                                                to: '/menuupunkt'
                                            }
                                        ]
                                    },
                                    {
                                        children: 'Finantsinfo',
                                        items: [
                                            {
                                                items: [
                                                    {
                                                        children: 'Lepingud',
                                                        to: '/menuupunkt'
                                                    }
                                                ]
                                            }
                                        ],
                                        to: ''
                                    }
                                ],
                                to: '/'
                            },
                            {
                                active: false,
                                children: 'Kiirlingid',
                                target: '_blank',
                                to: 'http://external.com'
                            },
                        ]}
                    />
                    <TTNewContainer>
                        <Layout>
                            <RouterProvider router={AppRouter}/>
                        </Layout>
                    </TTNewContainer>
                    <div className="footer"></div>
                </ErrorBoundary>
            </ConfigProvider>
        </>
    );
}

export default App;
