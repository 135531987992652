import {useFormStep} from '@hooks/useFormStep';
import {useStepChange} from '@hooks/useStepChange';
import {useTranslatedSteps} from '@hooks/useTranslatedSteps';
import {FormSubmitResult, StudyProgramFormData} from '@models/general-form.types';
import {FormActions, SubjectFields, TranslationScopes} from '@models/translations.model';
import {useState} from 'react';
import {DefaultValues, FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {ButtonGroup, Loader, Progress, TTNewButton, TTNewCol, TTNewRow} from 'taltech-styleguide';
import {FormResult} from '../Form/FormResult/FormResult.tsx';
import {removeEmptyValuesFromObject} from "@utils/removeEmptyValuesFromObject.ts";
import {useLoaderData, useNavigate} from "react-router-dom";
import {Routes} from "@models/routes.enum.ts";
import {saveStudyProgramForm} from "@utils/saveStudyProgramForm.ts";

export function StudyProgramForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [submitResult, setSubmitResult] = useState<
        FormSubmitResult | undefined
    >(undefined);
    const {t} = useTranslation();
    const navigate = useNavigate();

    let studyProgramData: StudyProgramFormData | null = null;
    const loaderData = useLoaderData() as [StudyProgramFormData] | null;
    if (loaderData !== null) {
        [studyProgramData] = loaderData;
    }

    /**
     * Instantiating the main form
     */
    const formMethods = useForm({
        mode: 'onBlur',
        shouldFocusError: false,
        defaultValues: studyProgramData as DefaultValues<StudyProgramFormData>,
    });
    const [step, setStep] = useState(0);
    const translatedSteps = useTranslatedSteps();
    const handleStepChange = useStepChange({setStep});

    const handleSaveDraft = async () => {
        setIsLoading(true);

        if (!await validateForm(true)) {
            setIsLoading(false);
            return;
        }

        const currentFormValues = removeEmptyValuesFromObject(formMethods.getValues());

        const response = await saveStudyProgramForm(currentFormValues, studyProgramData?.id) as {
            success: boolean,
            id: number
        };

        if (studyProgramData?.id) {
            setIsLoading(false);
            return;
        }
        if (response?.success) {
            navigate(`${Routes.CURRICULUM}/${response?.id}`, {replace: true});
            setIsLoading(false);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);

        if (!await validateForm(false)) {
            setIsLoading(false);
            return;
        }

        if (studyProgramData === null) {
            await handleSaveDraft();
            return;
        }

        const currentFormValues = removeEmptyValuesFromObject(formMethods.getValues());

        const response = await saveStudyProgramForm(currentFormValues, studyProgramData?.id) as {
            success: boolean,
            id: number
        };

        setSubmitResult(response);
    }

    const validateForm = async (isDraft: boolean): Promise<boolean> => {
        if (isDraft) {
            await formMethods.trigger([SubjectFields.NAME, SubjectFields.CODE]);

            if (formMethods.getFieldState(SubjectFields.NAME).invalid || formMethods.getFieldState(SubjectFields.CODE).invalid) {
                return false;
            }
        } else {
            await formMethods.trigger();
            const {isValid} = formMethods.formState;

            if (!isValid) {
                return false;
            }
        }

        return true;
    }

    /**
     * Using custom hook to simplify linking component to step number,
     * not strictly needed, as steps could also just be conditionally rendered
     */
    const stepComponent = useFormStep(step);
    if (submitResult) {
        /**
         * Turn off loader in case the user was shown the error screen and came back to the form
         */
        if (isLoading) {
            setIsLoading(false);
        }
        return (
            <>
                <Progress
                    aria-label="Progress"
                    currentStep={step}
                    lastCurrentStep={1}
                    steps={translatedSteps}
                />
                <FormResult
                    errorCallback={() => setSubmitResult(undefined)}
                    result={submitResult}
                />
            </>
        );
    }
    return (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
                <Progress
                    aria-label="Progress"
                    currentStep={step}
                    lastCurrentStep={1}
                    steps={translatedSteps}
                />
                <TTNewRow>
                    <TTNewCol size={6}>
                        {stepComponent}
                    </TTNewCol>
                </TTNewRow>
                {isLoading ? (
                    <Loader overlay/>
                ) : (
                    <ButtonGroup>
                        {step !== 0 && (
                            <TTNewButton
                                type="button"
                                onClick={() => handleStepChange(-1)}
                            >
                                {t(`${TranslationScopes.ACTIONS}.${FormActions.BACK}`)}
                            </TTNewButton>
                        )}
                        {step < 5 && (
                            <TTNewButton
                                type="button"
                                onClick={() => handleStepChange(1)}
                                disabled={step === translatedSteps?.length - 1}
                            >
                                {t(`${TranslationScopes.ACTIONS}.${FormActions.FORWARD}`)}
                            </TTNewButton>
                        )}
                        {step === 5 && (
                            <TTNewButton
                                type="submit"
                                variant="primary"
                            >
                                {t(`${TranslationScopes.ACTIONS}.${FormActions.SUBMIT}`)}
                            </TTNewButton>
                        )}
                        <TTNewButton
                            type="button"
                            variant="tertiary"
                            onClick={handleSaveDraft}
                        >
                            {t(`${TranslationScopes.ACTIONS}.${FormActions.SAVE}`)}
                        </TTNewButton>
                    </ButtonGroup>
                )}
            </form>
        </FormProvider>
    );
}
