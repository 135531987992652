import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ActionCard, Separator, TTNewButton, TTNewCol, TTNewRow, Typography} from 'taltech-styleguide';
import {FormSteps} from '@models/form-steps.enum';
import {FormValidators} from '@models/validation.model.ts';
import {FormActions, Labels, StudyFields, TranslationScopes, VideoFields} from '@models/translations.model';
import {getValidators} from '@validation/getValidators.ts';
import FormSelect from '@components/Form/FormSelect/FormSelect.tsx';
import FormInput from '@components/Form/FormInput/FormInput.tsx';
import {StudyGroups} from "@models/study-groups.enum.ts";
import {FieldArray, FieldValues, useFieldArray, useFormContext} from "react-hook-form";
import {defaultVideoObject} from "@constants/default-values.constant.ts";

export function ObjectivesStep() {
    const {t} = useTranslation();
    const {control, trigger} = useFormContext();
    const {fields, remove, append} = useFieldArray({
        control,
        name: StudyFields.VIDEOS,
    });

    const studyProgramrGroupOptions = useMemo(
        () => Object.values(StudyGroups)?.map((group) => ({
            label: t(`${TranslationScopes.STUDY_GROUPS}.${group}`),
            value: group
        })),
        [t]
    );

    const removeHandler = useCallback(
        (index: number) => {
            remove(index);
            trigger(StudyFields.VIDEOS);
        },
        [remove, trigger],
    );

    const videoLabel = t(`${TranslationScopes.LABELS}.${Labels.VIDEO}`);

    return (
        <ActionCard>
            <Typography as="h2">
                {t(`${TranslationScopes.STEPS}.${FormSteps.OBJECTIVES}`)}
            </Typography>

            <FormSelect
                id={StudyFields.GROUP}
                label={StudyFields.GROUP}
                registerOptions={{
                    ...getValidators([FormValidators.REQUIRED])
                }}
                values={studyProgramrGroupOptions}
                placeholder={t(
                    `${TranslationScopes.LABELS}.${Labels.GROUP_PLACEHOLDER}`
                )}
                multiple
                translationScope={TranslationScopes.STUDY_FIELDS}/>

            <FormInput
                as="textarea"
                id={StudyFields.TARGET_AUDIENCE}
                label={StudyFields.TARGET_AUDIENCE}
                translationScope={TranslationScopes.STUDY_FIELDS}
            />
            <FormInput
                as="textarea"
                id={StudyFields.OBJECTIVE}
                label={StudyFields.OBJECTIVE}
                translationScope={TranslationScopes.STUDY_FIELDS}
                registerOptions={{
                    ...getValidators([FormValidators.REQUIRED])
                }}
            />
            <FormInput
                as="textarea"
                id={StudyFields.LEARNING_OUTCOME}
                label={StudyFields.LEARNING_OUTCOME}
                translationScope={TranslationScopes.STUDY_FIELDS}
                registerOptions={{
                    ...getValidators([FormValidators.REQUIRED])
                }}
            />
            <FormInput
                as="textarea"
                id={StudyFields.PREREQUISITES}
                label={StudyFields.PREREQUISITES}
                translationScope={TranslationScopes.STUDY_FIELDS}
                registerOptions={{
                    ...getValidators([FormValidators.REQUIRED])
                }}
            />

            <Typography as="label" visual="h4" className="mb-0">
                {t(`${TranslationScopes.LABELS}.${Labels.VIDEOS}`)}
                <Typography color="gray-700" as="span" visual="label" modifiers={['italic', 'normal']}>
                    {` - `}{t(`${TranslationScopes.LABELS}.${Labels.OPTIONAL}`)}
                </Typography>
            </Typography>
            <Typography as="p">
                {t(`${TranslationScopes.LABELS}.${Labels.VIDEOS_DESCRIPTION}`)}
            </Typography>

            {fields?.map((object, i) => {
                return (
                    <div key={object?.id} className="mb-4">
                        <TTNewRow alignItems="center" justifyContent="start">
                            <TTNewCol size={2}>
                                <Typography as="span" visual="h5" className="mb-0">
                                    {i + 1}. {videoLabel}
                                </Typography>
                            </TTNewCol>
                            <TTNewCol>
                                <TTNewButton
                                    variant="tertiary"
                                    onClick={() => removeHandler(i)}
                                >
                                    {t(`${TranslationScopes.ACTIONS}.${FormActions.REMOVE}`)}
                                </TTNewButton>
                            </TTNewCol>
                        </TTNewRow>

                        <FormInput
                            key={`${StudyFields.VIDEOS}.${i}.${VideoFields.TITLE}`}
                            id={`${StudyFields.VIDEOS}.${i}.${VideoFields.TITLE}`}
                            label={VideoFields.TITLE}
                            translationScope={TranslationScopes.VIDEO_FIELDS}
                            registerOptions={{
                                ...getValidators([FormValidators.REQUIRED])
                            }}
                        />

                        <FormInput
                            key={`${StudyFields.VIDEOS}.${i}.${VideoFields.LINK}`}
                            id={`${StudyFields.VIDEOS}.${i}.${VideoFields.LINK}`}
                            label={VideoFields.LINK}
                            type="url"
                            translationScope={TranslationScopes.VIDEO_FIELDS}
                            registerOptions={{
                                ...getValidators([FormValidators.REQUIRED, FormValidators.URL])
                            }}
                        />

                        <FormInput
                            key={`${StudyFields.VIDEOS}.${i}.${VideoFields.ALT}`}
                            id={`${StudyFields.VIDEOS}.${i}.${VideoFields.ALT}`}
                            label={VideoFields.ALT}
                            translationScope={TranslationScopes.VIDEO_FIELDS}
                            registerOptions={{
                                ...getValidators([FormValidators.REQUIRED])
                            }}
                        />
                    </div>
                )
            })
            }

            <Separator/>
            <TTNewButton
                variant="secondary"
                onClick={() =>
                    append(defaultVideoObject as FieldArray<FieldValues>)
                }
            >
                {t(`${TranslationScopes.ACTIONS}.${FormActions.ADD}`)} {videoLabel}
            </TTNewButton>
        </ActionCard>
    );
}
