import {
    DashboardFields,
    FormActions,
    FormErrors,
    Labels,
    LoginFormFields,
    ModuleFields,
    StudyFields,
    SubjectFields,
    Titles,
    TranslationScopes,
    TranslationsObject,
    VideoFields,
} from '@models/translations.model';
import {FormSteps} from "@models/form-steps.enum.ts";
import {EducationTypes} from "@models/education-types.enum.ts";
import {Languages} from "@models/languages.enum.ts";
import {StudyGroups} from "@models/study-groups.enum.ts";
import {DocumentTypes} from "@models/document-types.enum.ts";

const etTranslations: TranslationsObject = {
    [TranslationScopes.ACTIONS]: {
        [FormActions.SEND]: 'Saada',
        [FormActions.BACK]: 'Tagasi',
        [FormActions.BACK_TO_DASHBOARD]: 'Suundu tagasi töölauale',
        [FormActions.ADD]: 'Lisa',
        [FormActions.FORWARD]: 'Edasi',
        [FormActions.SAVE]: 'Salvesta',
        [FormActions.REDIRECT_AUTH_ID]: 'Suuna id-kaardiga autentimise lehele',
        [FormActions.SUBMIT]: 'Saada koolitusjuhile',
        [FormActions.REMOVE]: 'Eemalda',
    },
    [TranslationScopes.DASHBOARD]: {
        [DashboardFields.STATUS]: 'Staatus',
        [DashboardFields.NOTIFICATIONS]: 'Teavitused',
        [DashboardFields.ALL_COURSES]: 'Vaata kõiki aineid',
        [DashboardFields.ALL_STUDY_PROGRAMMES]: 'Vaata kõiki õppekavasid',
        [DashboardFields.CREATE_NEW_STUDY_PROGRAMME]: 'Loo uus õppekava',
        [DashboardFields.COURSES_TITLE]: 'Sinu ained',
        [DashboardFields.STUDY_PROGRAMMES]: 'Õppekavad',
        [DashboardFields.STUDY_PROGRAMMES_TITLE]: 'Sinu õppekavad',
        [DashboardFields.SCHEDULE]: 'Toimumisaeg',
        [DashboardFields.ECTS_CREDITS]: 'Õppemaht',
        [DashboardFields.PUBLISHED]: 'Avaldatud',
        [DashboardFields.DRAFT]: 'Mustand',
    },
    [TranslationScopes.DOCUMENT_TYPES]: {
        [DocumentTypes.CERTIFICATE]: 'Sertifikaat',
        [DocumentTypes.COMPLETION_CERTIFICATE]: 'Tunnistus',
        [DocumentTypes.ATTENDANCE_CERTIFICATE]: 'Tõend',
    },
    [TranslationScopes.EDUCATION_TYPES]: {
        [EducationTypes.MICRO]: 'Mikrokraad',
        [EducationTypes.CONTINUING_EDUCATION]: 'Täiendusõpe',
    },
    [TranslationScopes.ERRORS]: {
        [FormErrors.REQUIRED]: 'Nõutud väli!',
        [FormErrors.URL]: 'Vigane URL',
        [FormErrors.EMAIL]: 'Vale e-posti address',
        [FormErrors.NO_MATCHES]: 'Vasted puuduvad',
        [FormErrors.SELECT_SEARCH_RESULT]: 'Vali otsingu vastetest',
        [FormErrors.DATE]: 'Vigane kuupäev',
        [FormErrors.ARRAY_REQUIRED]: 'Nimekirjas leidub tühju nõutud välju',
        [FormErrors.ARRAY_EMPTY]: 'Nimekiri on tühi',
        [FormErrors.UNKNOWN_ERROR]: 'Midagi läks valesti',
        [FormErrors.INVALID_OR_MISSING_EMAIL]:
            'Sisestatud email on vigane või ei eksisteeri personalitarkvaras',
    },
    [TranslationScopes.LABELS]: {
        [Labels.ADDITIONAL_PRICING_INFO_PLACEHOLDER]: 'Kirjelda, kuidas täiendkoolitus hinnastatakse',
        [Labels.ADD_NUMBER]: 'Lisa number',
        [Labels.COMPLETION_DOCUMENT_PLACEHOLDER]: 'Vali dokumendi tüüp',
        [Labels.COMPLETION_REQUIREMENTS_PLACEHOLDER]: 'Kirjelda hindamiskriteeriume',
        [Labels.COURSE]: 'aine',
        [Labels.COURSE_INFO_TITLE]: 'Aine põhiandmed',
        [Labels.COURSE_OBJECTIVES_TITLE]: 'Aine eesmärgid',
        [Labels.COURSE_ORGANIZATION_TITLE]: 'Aine õppekorraldus',
        [Labels.DATA_PARTIALLY_INVALID]: 'Ankeedil on täitmata välju',
        [Labels.DATA_PARTIALLY_INVALID_DESCRIPTION]: 'Ankeedis esineb puudusi, mistõttu ei saanud seda esitada. Selleks, et isikuandmete ankeeti edukalt esitada, peavad kõik kohustuslikud väljad olema korrektselt täidetud. Palun täienda järgnevaid välju ja proovi uuesti esitada:',
        [Labels.DATA_SUBMITTED]: 'Täiendusõppekava on koolitusjuhile edastatud ',
        [Labels.DATE_PLACEHOLDER]: 'Vali kuupäev',
        [Labels.ECTS_CREDITS]: 'Maht EAP',
        [Labels.GROUP_PLACEHOLDER]: 'Vali õppekavarühm',
        [Labels.HOURS_TOTAL_DESCRIPTION]: 'Kogumaht arvutatakse kokku auditoorse õppe, praktilise ja iseseisva töö mahtudest.',
        [Labels.INDEPENDENT_HOURS]: 'Iseseisva töö maht (ak t)',
        [Labels.LANGUAGE_PLACEHOLDER]: 'Vali õppekeel',
        [Labels.LEARNING_OUTCOME_PLACEHOLDER]: 'Kirjelda õpiväljundeid',
        [Labels.LECTURE_HOURS]: 'Auditoorse õppe maht (ak t)',
        [Labels.LOCATION_PLACEHOLDER]: 'Kirjelda, kus õppetöö toimub',
        [Labels.LOGIN_AUTHENTICATION]: 'Enne ankeedi täitmist pead end autentima. Saad seda teha ID-kaardiga või e-posti teel.',
        [Labels.LOGIN_AUTH_EMAIL]: 'Saada kinnituskood e-posti aadressile:',
        [Labels.LOGIN_AUTH_ID]: 'Autendi end ID-kaardiga:',
        [Labels.LOGIN_LIST_DESCRIPTION]: `Enne isikuandmete ankeedi täitmist soovitame Sul varuda aega ning leida üles vajalikud dokumendid. Sul läheb vaja:\n<0></0> Ära muretse, kui Sul pole neid dokumente kohe käepärast, sest saad ankeedi täitmise pooleli jätta ja hiljem jätkata, kuni oled valmis vajutama "Esita".`,
        [Labels.LOGIN_LIST_FIRST]: 'fotot endast',
        [Labels.LOGIN_LIST_SECOND]: 'isikut tõendava dokumendi koopiat',
        [Labels.LOGIN_LIST_THIRD]: 'kõrgeima lõpetatud haridustaseme dokumendi koopiat',
        [Labels.MODULE]: 'moodul',
        [Labels.MORE_RESULTS]: 'Näita rohkem vasteid',
        [Labels.NEXT_STEP]: 'Koolitusjuht vaatab edastatud täiendusõppekava üle, lisab puuduolevad andmed ning kinnitab õppekava või saadab Sulle vormi tagasi täiendamiseks.',
        [Labels.OBJECTIVE_PLACEHOLDER]: 'Kirjelda aine eesmärke',
        [Labels.OPTIONAL]: 'valikuline',
        [Labels.PRACTICAL_HOURS]: 'Praktilise töö maht (ak t)',
        [Labels.PREREQUISITES_PLACEHOLDER]: 'Kirjelda aine eeldusi',
        [Labels.PREREQUISITE_COURSES_PLACEHOLDER]: 'Vali aine(d), mis on aine läbimiseks eelduseks',
        [Labels.PROGRESS]: 'Sammud',
        [Labels.QUESTIONS_CONTACT]: 'Küsimuste korral võta ühendust koolitusjuhiga või <0>{{linkContents}}</0>.',
        [Labels.SEARCH_PLACEHOLDER]: 'Otsi märksõna, dokumenti, töötajat',
        [Labels.SELECT_MODULE_PLACEHOLDER]: 'Vali moodul',
        [Labels.TARGET_AUDIENCE_PLACEHOLDER]: 'Kirjelda sihtgruppi',
        [Labels.TOTAL_HOURS]: 'Õppe kogumaht (ak t)',
        [Labels.VIDEO]: 'Video',
        [Labels.VIDEOS]: 'Lisa õppekava tutvustav video',
        [Labels.VIDEOS_DESCRIPTION]: 'Videot kasutatakse täiendusõppekava reklaamimiseks veebis.',

    },
    [TranslationScopes.LANGUAGES]: {
        [Languages.EN]: 'Inglise keel',
        [Languages.ET]: 'Eesti keel',
        [Languages.RU]: 'Vene keel',
    },
    [TranslationScopes.LOGIN_FIELDS]: {
        [LoginFormFields.NAME]: 'Nimi',
        [LoginFormFields.EMAIL]: 'E-mail',
        [LoginFormFields.CODE]: 'Kood',
    },
    [TranslationScopes.MODULE_FIELDS]: {
        [ModuleFields.NAME]: 'Mooduli nimetus',
        [ModuleFields.OBJECTIVE]: 'Mooduli eesmärk',
    },
    [TranslationScopes.STEPS]: {
        [FormSteps.MODULES]: 'Moodulid',
        [FormSteps.GENERAL_INFO]: 'Põhiandmed',
        [FormSteps.OBJECTIVES]: 'Eesmärgid',
        [FormSteps.COURSES]: 'Ained',
        [FormSteps.MANAGEMENT]: 'Õppekorraldus',
        [FormSteps.CONDITIONS]: 'Õppekava tingimused',
    },
    [TranslationScopes.STUDY_FIELDS]: {
        [StudyFields.NAME]: 'Õppekava nimetus',
        [StudyFields.CODE]: 'Õppekava kood',
        [StudyFields.STUDY_PROG_INTRO_LANG]: 'Õppekava tutvustuse keel',
        [StudyFields.INSTRUCTION_LANGUAGE]: 'Õppekeel',
        [StudyFields.TYPE]: 'Tüüp',
        [StudyFields.START_DATE]: 'Toimumisaja algus (PP.KK.AAAA)',
        [StudyFields.END_DATE]: 'Toimumisaja lõpp (PP.KK.AAAA)',
        [StudyFields.GROUP]: 'Õppekavarühm',
        [StudyFields.TARGET_AUDIENCE]: 'Sihtgrupp',
        [StudyFields.OBJECTIVE]: 'Eesmärk',
        [StudyFields.LEARNING_OUTCOME]: 'Õpiväljundid',
        [StudyFields.PREREQUISITES]: 'Õpingute alustamise tingimused',
        [StudyFields.LECTURERS]: 'Koolitajad',
        [StudyFields.HEAD_OF_TRAINING]: 'Koolitusjuht',
        [StudyFields.ENVIRONMENT_DESCRIPTION]: 'Õppekeskkonna kirjeldus',
        [StudyFields.STUDY_MATERIALS]: 'Õppematerjalide loend',
        [StudyFields.REGISTRATION_START_DATE]: 'Registreerumine alates (PP.KK.AAAA)',
        [StudyFields.REGISTRATION_END_DATE]: 'Registreerumine kuni (PP.KK.AAAA)',
        [StudyFields.COMPLETION_REQUIREMENTS]: 'Nõuded lõpetamiseks',
        [StudyFields.COMPLETION_DOCUMENT]: 'Lõpetajale väljstatav dokument',
        [StudyFields.CONT_EDUCATION_COST]: 'Täiendusõppekava maksumus (€)',
        [StudyFields.ADDITIONAL_PRICING_INFO]: 'Täiendav info hinna kohta',
    },
    [TranslationScopes.STUDY_GROUPS]: {
        [StudyGroups.GENERAL_CURRICULUM]: 'general_curriculum',
        [StudyGroups.EDUCATIONAL]: 'educational',
        [StudyGroups.LIBERAL_ARTS]: 'liberal_arts_in_humanities',
        [StudyGroups.SOCIAL_SCIENCES]: 'social_sciences_journalism_and_information_dissemination',
        [StudyGroups.BUSINESS_ADMIN]: 'business_administration_and_law',
        [StudyGroups.NATURAL_SCIENCES]: 'natural_sciences_mathematics_and_statistics',
        [StudyGroups.IC_TECHNOLOGIES]: 'information_and_communication_technologies',
        [StudyGroups.ENGINEERING]: 'engineering_manufacturing_and_construction',
        [StudyGroups.AGRICULTURE]: 'agriculture_forestry_fisheries_and_veterinary',
        [StudyGroups.HEALTH_AND_WELFARE]: 'health_and_welfare',
        [StudyGroups.SERVICES]: 'services',
    },
    [TranslationScopes.SUBJECT_FIELDS]: {
        [SubjectFields.MODULE]: 'Moodul',
        [SubjectFields.CODE]: 'Aine kood',
        [SubjectFields.NAME]: 'Aine nimetus',
        [SubjectFields.START_DATE]: 'Aine algus (DD.MM.YYYY)',
        [SubjectFields.END_DATE]: 'Aine lõpp (DD.MM.YYYY)',
        [SubjectFields.INSTRUCTION_LANGUAGE]: 'Õppekeel',
        [SubjectFields.TARGET_AUDIENCE]: 'Sihtgrupp',
        [SubjectFields.OBJECTIVE]: 'Eesmärk',
        [SubjectFields.LEARNING_OUTCOME]: 'Õpiväljundid',
        [SubjectFields.PREREQUISITES]: 'Eeldused',
        [SubjectFields.PREREQUISITE_COURSES]: 'Eeldusained',
        [SubjectFields.LOCATION]: 'Asukoht',
    },
    [TranslationScopes.TITLES]: {
        [Titles.WELCOME]: 'Tere tulemast!',
        [Titles.AUTHENTICATION]: 'Autentimine',
    },
    [TranslationScopes.VIDEO_FIELDS]: {
        [VideoFields.LINK]: 'Video link',
        [VideoFields.TITLE]: 'Video pealkiri',
        [VideoFields.ALT]: 'Video alt tekst',
    }
}

export default etTranslations;
