import {FormDateRangeProps} from '@models/form-input.types.ts';
import {useTranslation} from 'react-i18next';
import {DateRangePicker, FormFeedback, STATUS_TYPE, Typography} from 'taltech-styleguide';
import {Labels, TranslationScopes} from '@models/translations.model.ts';
import {Controller, useFormContext} from "react-hook-form";

export default function FormDateRangePicker<InputId extends string>({
                                                                        firstDateId,
                                                                        secondDateId,
                                                                        translationScope,
                                                                        infoLabel,
                                                                        optional
                                                                    }: FormDateRangeProps<InputId>) {
    const {t} = useTranslation();
    const {
        control,
        setValue
    } = useFormContext();

    return (
        <div>
            <Controller
                control={control}
                name="date"
                rules={{required: true}}
                render={() => {
                    return (
                        <DateRangePicker
                            separator=""
                            clearable={true}
                            onChange={(newValue: {
                                startDate: { format: (arg0: string) => any; };
                                endDate: { format: (arg0: string) => any; };
                            }) => {
                                setValue(firstDateId, newValue?.startDate?.format('YYYY-MM-DD'));
                                setValue(secondDateId, newValue?.endDate?.format('YYYY-MM-DD'));
                            }}
                            slotProps={{
                                openPickerIcon: {
                                    name: 'calendar_month'
                                },
                                start: {
                                    textField: {
                                        id: firstDateId,
                                        clearable: true,
                                        label: (
                                            <Typography color="gray-800" as="label" htmlFor={firstDateId}>
                                                {t(`${translationScope}.${firstDateId}`)}
                                                {optional ?
                                                    <Typography color="gray-700" as="span" modifiers={['italic']}>
                                                        {` - `}{t(`${TranslationScopes.LABELS}.${Labels.OPTIONAL}`)}
                                                    </Typography> : null}
                                            </Typography>
                                        ),
                                        name: firstDateId,
                                        placeholder: t(`${TranslationScopes.LABELS}.${Labels.DATE_PLACEHOLDER}`)
                                    }
                                },
                                end: {
                                    textField: {
                                        id: secondDateId,
                                        clearable: true,
                                        label: (
                                            <Typography color="gray-800" as="label" htmlFor={secondDateId}>
                                                {t(`${translationScope}.${secondDateId}`)}
                                                {optional ?
                                                    <Typography color="gray-700" as="span" modifiers={['italic']}>
                                                        {` - `}{t(`${TranslationScopes.LABELS}.${Labels.OPTIONAL}`)}
                                                    </Typography> : null}
                                            </Typography>
                                        ),
                                        name: secondDateId,
                                        placeholder: t(`${TranslationScopes.LABELS}.${Labels.DATE_PLACEHOLDER}`)
                                    },
                                },
                            }}
                        />
                    );
                }}
            />
            {infoLabel ? (
                <FormFeedback type={STATUS_TYPE.INFO}>
                    {t(`${TranslationScopes.LABELS}.${infoLabel}`)}
                </FormFeedback>
            ) : null}
        </div>
    );
}
