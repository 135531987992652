/**
 * Matches anything that isn't whitespace followed with an @ and two more non-whitespace separated with a dot
 */
export const EmailRegex = /\S+@\S+\.\S+/;

/**
 * Matches dd.MM.YYYY
 */
export const DateRegex =
    /(0[1-9]|1[0-9]|2[0-9]|3[01])\.(0[1-9]|1[012])\.([0-9]{4})/gm;

export const UrlRegex = /^(https?:\/\/)?([\\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

/**
 * Matches all hyphens
 */
export const HyphenRegex = /-/g;
