import {authTokenKey} from '@constants/default-values.constant';
import {Routes} from '@models/routes.enum';
import {getCookie} from '@utils/getCookie';
import {redirect} from 'react-router-dom';

/**
 * Loader method to redirect if user is already authed
 */
export function authenticatedLoader(): Response | null {
    const isAuthed = !!getCookie(authTokenKey);
    /**
     * Redirect to form if user is logged in
     */
    if (isAuthed) {
        return redirect(Routes.INDEX);
    }

    return null;
}
