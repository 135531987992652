import {authTokenKey} from '@constants/default-values.constant';
import {ApiStatusCodes} from '@models/api.model';
import {Routes} from '@models/routes.enum';
import {deleteCookie} from '@utils/deleteCookie';
import {getCookie} from '@utils/getCookie';
import {redirect} from 'react-router-dom';
import {fetchDashboardData} from '@utils/fetchDashboardData.ts';
import {DashboardFields} from "@models/translations.model.ts";

/**
 * Loader method to fetch form if user is logged in
 */
export async function dashboardLoader(): Promise<unknown> {
    const isAuthed = !!getCookie(authTokenKey);
    /**
     * Redirect to log in if user is not logged in
     */
    if (!isAuthed) {
        return redirect(Routes.LOGIN);
    }

    const data = await fetchDashboardData();
    if (data?.[DashboardFields.STATUS] === ApiStatusCodes.UNAUTHENTICATED) {
        /**
         * Delete the old cookie and re-route user to login if cookie is invalid
         */
        deleteCookie(authTokenKey);
        return redirect(Routes.LOGIN);
    }

    return [
        data[DashboardFields.STUDY_PROGRAMMES],
        data[DashboardFields.COURSES],
        data[DashboardFields.NOTIFICATIONS],
    ];
}
