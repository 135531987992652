import {PropsWithChildren} from 'react';

type ExternalLinkProps = PropsWithChildren &
    Partial<HTMLAnchorElement> & {
    openInNewTab?: boolean;
    moveIconHigher?: boolean;
};

function ExternalLink({
                          children,
                          href,
                          moveIconHigher,
                          openInNewTab = true,
                      }: ExternalLinkProps) {
    // Add extra custom class if needed, multi-line text seems to break icon height
    const iconClasses = `tt-new-icon tt-new-icon--inline material-symbols-outlined tt-new-anchor__icon--new-tab${
        moveIconHigher ? ' external-link--higher-icon' : ''
    }`;
    return (
        // The rel string constant contains the necessary values
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
            className={openInNewTab ? 'tt-new-anchor--new-tab' : undefined}
            href={href}
            rel="noreferrer noopener"
            target={openInNewTab ? '_blank' : undefined}
        >
            {children}
            {openInNewTab ? (
                <span role="img" aria-hidden="true" className={iconClasses}>
          north_east
        </span>
            ) : null}
        </a>
    );
}

export default ExternalLink;
