import {FormSteps} from '@models/form-steps.enum';
import {Labels, StudyFields, TranslationScopes} from '@models/translations.model';
import {useTranslation} from 'react-i18next';
import {ActionCard, TTNewCol, TTNewRow, Typography} from 'taltech-styleguide';
import FormInput from "@components/Form/FormInput/FormInput.tsx";
import {getValidators} from "@validation/getValidators.ts";
import {FormValidators} from "@models/validation.model.ts";
import {useFormContext} from "react-hook-form";
import FormDateRangePicker from "@components/Form/FormDateRangePicker/FormDateRangePicker.tsx";
import calculateFieldTotal from "@utils/calculateFieldTotal.ts";

export function ManagementStep() {
    const {t} = useTranslation();
    const {getValues} = useFormContext();

    const formValues = getValues(StudyFields.COURSES);

    const contactHoursTotal = calculateFieldTotal(formValues, Labels.LECTURE_HOURS);
    const placementHoursTotal = calculateFieldTotal(formValues, Labels.PRACTICAL_HOURS);
    const independentHoursTotal = calculateFieldTotal(formValues, Labels.INDEPENDENT_HOURS);
    const ectsCreditsTotal = calculateFieldTotal(formValues, Labels.ECTS_CREDITS);

    const curriculumHoursTotal = contactHoursTotal + placementHoursTotal + independentHoursTotal;
    return (
        <ActionCard>
            <Typography as="h2">
                {t(`${TranslationScopes.STEPS}.${FormSteps.MANAGEMENT}`)}
            </Typography>

            <TTNewRow>
                <TTNewCol>
                    <FormInput
                        key={`${Labels.LECTURE_HOURS}`}
                        id={`${Labels.LECTURE_HOURS}`}
                        label={Labels.LECTURE_HOURS}
                        type="number"
                        value={contactHoursTotal}
                        disabled
                        placeholder={t(`${TranslationScopes.LABELS}.${Labels.ADD_NUMBER}`)}
                        translationScope={TranslationScopes.LABELS}
                        registerOptions={{
                            ...getValidators([FormValidators.REQUIRED])
                        }}
                    />
                </TTNewCol>
                <TTNewCol>
                    <FormInput
                        key={`${Labels.PRACTICAL_HOURS}`}
                        id={`${Labels.PRACTICAL_HOURS}`}
                        label={Labels.PRACTICAL_HOURS}
                        type="number"
                        value={placementHoursTotal}
                        disabled
                        placeholder={t(`${TranslationScopes.LABELS}.${Labels.ADD_NUMBER}`)}
                        translationScope={TranslationScopes.LABELS}
                        registerOptions={{
                            ...getValidators([FormValidators.REQUIRED])
                        }}
                    />
                </TTNewCol>
                <TTNewCol>
                    <FormInput
                        key={`${Labels.INDEPENDENT_HOURS}`}
                        id={`${Labels.INDEPENDENT_HOURS}`}
                        label={Labels.INDEPENDENT_HOURS}
                        type="number"
                        value={independentHoursTotal}
                        disabled
                        placeholder={t(`${TranslationScopes.LABELS}.${Labels.ADD_NUMBER}`)}
                        translationScope={TranslationScopes.LABELS}
                        registerOptions={{
                            ...getValidators([FormValidators.REQUIRED])
                        }}
                    />
                </TTNewCol>
            </TTNewRow>

            <FormInput
                id={Labels.TOTAL_HOURS}
                label={Labels.TOTAL_HOURS}
                translationScope={TranslationScopes.LABELS}
                value={curriculumHoursTotal}
                infoLabel={Labels.HOURS_TOTAL_DESCRIPTION}
                size={3}
                disabled={true}
                registerOptions={{
                    ...getValidators([FormValidators.REQUIRED])
                }}
            />

            <FormInput
                key={`${Labels.ECTS_CREDITS}`}
                id={`${Labels.ECTS_CREDITS}`}
                label={Labels.ECTS_CREDITS}
                value={ectsCreditsTotal}
                disabled
                placeholder={t(`${TranslationScopes.LABELS}.${Labels.ADD_NUMBER}`)}
                translationScope={TranslationScopes.LABELS}
            />
            <FormInput
                as="textarea"
                key={`${StudyFields.ENVIRONMENT_DESCRIPTION}`}
                id={`${StudyFields.ENVIRONMENT_DESCRIPTION}`}
                label={StudyFields.ENVIRONMENT_DESCRIPTION}
                placeholder={t(`${TranslationScopes.STUDY_FIELDS}.${StudyFields.ENVIRONMENT_DESCRIPTION}`)}
                translationScope={TranslationScopes.STUDY_FIELDS}
                registerOptions={{
                    ...getValidators([FormValidators.REQUIRED])
                }}
            />
            <FormInput
                as="textarea"
                key={`${StudyFields.STUDY_MATERIALS}`}
                id={`${StudyFields.STUDY_MATERIALS}`}
                label={StudyFields.STUDY_MATERIALS}
                placeholder={t(`${TranslationScopes.STUDY_FIELDS}.${StudyFields.STUDY_MATERIALS}`)}
                translationScope={TranslationScopes.STUDY_FIELDS}
            />
            <FormDateRangePicker
                id={`${StudyFields.REGISTRATION_START_DATE}-${StudyFields.REGISTRATION_END_DATE}`}
                firstDateId={StudyFields.REGISTRATION_START_DATE}
                secondDateId={StudyFields.REGISTRATION_END_DATE}
                translationScope={TranslationScopes.STUDY_FIELDS}
            />
        </ActionCard>
    );
}
