import {ReactElement, useState} from "react";
import {Button, Collapse, Icon, Typography} from "taltech-styleguide";
import {FormAccordionProps} from "@models/form-input.types.ts";

import * as styles from './FormAccordion.module.scss';

export default function FormAccordion({
                                          id,
                                          name,
                                          isOpen,
                                          children
                                      }: FormAccordionProps): ReactElement {
    const [open, setOpen] = useState(isOpen);

    return (
        <>
            <Button
                aria-controls={id}
                id={id}
                className={styles.toggleButton}
                onClick={() => setOpen(!open)}
                aria-expanded={open}
                color="primary"
                noStyle>
                <Typography as="b">{name}</Typography> <Icon name={open ? "expand_less" : "expand_more"}/>
            </Button>
            <Collapse in={open} aria-labelledby={id}>
                <div id={id} className="mb-3">
                    {children}
                </div>
            </Collapse>
        </>
    );
}
