import {authTokenKey} from '@constants/default-values.constant';
import {Routes} from '@models/routes.enum';
import {FormActions, FormErrors, Labels, LoginFormFields, TranslationScopes} from '@models/translations.model';
import {ApiEndpoints, ContentTypes, RequestMethods} from '@models/api.model';
import {EmailLoginResponse, EmailRegisterResponse} from '@models/auth.model';
import {FormValidators} from '@models/validation.model';
import {handleFetch} from '@utils/handleFetch';
import {setCookie} from '@utils/setCookie';
import {getValidators} from '@validation/getValidators';
import {useCallback, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {FormFeedback, Loader, STATUS_TYPE, TTNewButton, Typography} from 'taltech-styleguide';
import FormInput from '@components/Form/FormInput/FormInput';

export function EmailLogin() {
    const {t} = useTranslation();
    const [error, setError] = useState<FormErrors | undefined>(undefined);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const formMethods = useForm({});
    const navigate = useNavigate();
    const onRegister = useCallback(
        async (data: unknown) =>
            handleFetch<EmailRegisterResponse>({
                endpoint: ApiEndpoints.EMAIL_REGISTER,
                method: RequestMethods.POST,
                headers: {'Content-Type': ContentTypes.JSON},
                data,
                throwOnError: false,
            }).then((res) => {
                if (res?.status === 204) {
                    /**
                     * Clear error if error was set
                     */
                    setError(undefined);
                    setIsEmailSent(true);
                } else {
                    /**
                     * Check if we have a translation for the returned error
                     * If we do, use it
                     */
                    const knownMessage = res?.message
                        ? FormErrors?.[
                            res?.message?.toUpperCase() as keyof typeof FormErrors
                            ]
                        : undefined;
                    if (knownMessage) {
                        setError(knownMessage);
                    } else {
                        setError(FormErrors.UNKNOWN_ERROR);
                    }
                }
            }),
        [],
    );
    const onLogin = useCallback(
        async (data: unknown) =>
            handleFetch<EmailLoginResponse>({
                endpoint: ApiEndpoints.EMAIL_LOGIN,
                method: RequestMethods.POST,
                headers: {'Content-Type': ContentTypes.JSON},
                data,
            })
                .then((res) => {
                    if (res?.token) {
                        /**
                         * Clear error if error was set
                         */
                        setError(undefined);
                        /**
                         * Set the token if request was successful
                         */
                        setCookie(authTokenKey, res?.token);
                        navigate(Routes.INDEX);
                    }
                })
                .catch(() => setError(FormErrors.UNKNOWN_ERROR)),
        [navigate],
    );
    /**
     * Switch handlers after the first email has been sent
     */
    const submitHandler = isEmailSent ? onLogin : onRegister;
    return (
        <div>
            <Typography as="span">
                {t(`${TranslationScopes.LABELS}.${Labels.LOGIN_AUTH_EMAIL}`)}
            </Typography>
            <FormProvider {...formMethods}>
                <form onSubmit={formMethods.handleSubmit(submitHandler)}>
                    {formMethods?.formState?.isSubmitting ? <Loader/> : null}
                    {!formMethods?.formState?.isSubmitting && isEmailSent ? (
                        <FormInput
                            id={LoginFormFields.CODE}
                            translationScope={TranslationScopes.LOGIN_FIELDS}
                            registerOptions={{
                                ...getValidators([FormValidators.REQUIRED]),
                            }}
                        />
                    ) : null}
                    {!formMethods?.formState?.isSubmitting && !isEmailSent ? (
                        <>
                            <FormInput
                                id={LoginFormFields.EMAIL}
                                label={LoginFormFields.EMAIL}
                                translationScope={TranslationScopes.LOGIN_FIELDS}
                                registerOptions={{
                                    ...getValidators([
                                        FormValidators.REQUIRED,
                                        FormValidators.EMAIL,
                                    ]),
                                }}
                            />
                        </>
                    ) : null}
                    {formMethods?.formState?.isSubmitting ? null : (
                        <TTNewButton type="submit">
                            {t(`${TranslationScopes.ACTIONS}.${FormActions.SEND}`)}
                        </TTNewButton>
                    )}
                    {error ? (
                        <FormFeedback type={STATUS_TYPE.DANGER} role="alert">
                            {t(`${TranslationScopes.ERRORS}.${error}`)}
                        </FormFeedback>
                    ) : null}
                </form>
            </FormProvider>
        </div>
    );
}
