import {authTokenKey} from '@constants/default-values.constant';
import {Routes} from '@models/routes.enum';
import {getCookie} from '@utils/getCookie';
import {redirect} from 'react-router-dom';
import {fetchSingleCurriculum} from "@utils/fetchSingleCurriculum.ts";
import {parseStudyProgramData} from "@utils/parseStudyProgramData.ts";

/**
 * Loader method to fetch form if user is logged in
 */
export async function studyProgramLoader({params}: {params?: {curriculumId?: number}}): Promise<unknown> {
    const isAuthed = !!getCookie(authTokenKey);
    /**
     * Redirect to log in if user is not logged in
     */
    if (!isAuthed) {
        return redirect(Routes.LOGIN);
    }

    if ( params?.curriculumId ) {
        const studyProgramData = await fetchSingleCurriculum(params.curriculumId);
        if ( studyProgramData?.status === 200 ) {
            return [parseStudyProgramData(studyProgramData?.data)];
        }
    }

    return null;
}
